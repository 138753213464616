import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import Toaster from '../plugin/Toaster';
import { usePasswordConfirmed } from '../hooks/usePasswordConfirmed';
import { userApi } from '../api';
import { Utils } from '../utils';

export default function ResetPassword() {
    const navigate = useNavigate();

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [updateSuccessful, setUpdateSuccessful] = useState(false);

    const userId = useParams().userId;
    const passwordConfirmed = usePasswordConfirmed(
        newPassword,
        confirmPassword
    );

    async function resetPassword(event) {
        event.preventDefault();

        if (updateSuccessful) {
            navigate('/sign-in');
            return;
        }

        if (!Utils.isPasswordValid(newPassword)) {
            Toaster.error(
                'Please make sure your password contains at least 1 uppercase letter, 1 lowercase letter, 1 special character and 1 number'
            );
            return;
        }

        if (!passwordConfirmed) {
            Toaster.warning('Please enter matching passwords');

            return;
        }

        try {
            await userApi.forgotPassword(userId, newPassword);
        } catch (e) {
            Utils.showBackendError(e);
        }

        setUpdateSuccessful(true);
        Toaster.success(
            'You have successfully updated your password. You can now navigate to the login page and sign in.'
        );
    }

    return (
        <SectionFanFeatureMain>
            <FanForumOuter>
                <ForgotPasswordTitle>Reset Password</ForgotPasswordTitle>
                <Request>Change your password</Request>

                <ForgotPasswordTitleForm>
                    <InputField>
                        <Label>New password</Label>
                        <Input
                            type="password"
                            onChange={(e) => setNewPassword(e.target.value)}
                        ></Input>
                    </InputField>

                    <InputField>
                        <Label>Confirm password</Label>
                        <Input
                            type="password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        ></Input>
                    </InputField>

                    <Button onClick={(event) => resetPassword(event)}>
                        {updateSuccessful ? 'LOGIN' : 'SAVE'}
                    </Button>
                </ForgotPasswordTitleForm>
            </FanForumOuter>
        </SectionFanFeatureMain>
    );
}

const SectionFanFeatureMain = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(
        45deg,
        rgba(61, 37, 63, 1) 0%,
        rgba(19, 32, 41, 1) 50%,
        rgba(32, 81, 87, 1) 100%
    );
    height: 70vh;
`;

const FanForumOuter = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    padding: 50px;
    margin: 100px 10px 100px 10px;

    width: 30%;

    background-image: linear-gradient(
        240deg,
        hsl(295deg 26% 20%) 0%,
        hsl(242deg 23% 19%) 20%,
        hsl(212deg 37% 14%) 40%,
        hsl(200deg 40% 14%) 60%,
        hsl(193deg 45% 19%) 80%,
        hsl(187deg 46% 23%) 100%
    );

    border-radius: 25px;

    @media (max-width: 768px) {
        width: 90%;
        margin: 20px 10px 20px 10px;
    }
`;

const ForgotPasswordTitle = styled.h2`
    text-transform: uppercase;
    font-family: 'Lucida Grande', serif;
    letter-spacing: 2px;
    color: #adb9d8;
    font-size: 30px;
    font-weight: 400;
    margin: 0;
    text-align: center;
`;

const Request = styled.p`
    font-size: 16px;
    color: #adb9d8;
    font-family: 'avenir_lt_std55_roman', serif;
    margin-top: 20px;
    text-align: center;
    text-transform: uppercase;
`;

const ForgotPasswordTitleForm = styled.form`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
`;

const InputField = styled.div`
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    border: 0;
    height: 45px;
    max-width: 481px;
    width: 100%;
    margin-top: 16px;

    padding: 10px 20px;
`;

const Label = styled.label`
    font-size: 12px;
    line-height: 12px;
    color: #adb0b1;
    font-family: 'avenir_lt_std55_roman', serif;
    text-align: left;
`;

const Input = styled.input`
    outline: none;
    border: 0;
    font-size: 15px;
    line-height: 20px;
    color: #475063;
    font-family: 'avenir_lt_std55_roman', serif;
    text-align: left;
`;

const Button = styled.button`
    background-color: #b7e9ff;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    font-family: 'avenir_lt_std95_black', serif;
    height: 55px;
    border-radius: 15px;
    margin-top: 20px;
    transition: 0.5s ease;
    border: 2px solid transparent;

    &:hover {
        cursor: pointer;
        background-image: linear-gradient(
            240deg,
            hsl(295deg 26% 20%) 0%,
            hsl(242deg 23% 19%) 20%,
            hsl(212deg 37% 14%) 40%,
            hsl(200deg 40% 14%) 60%,
            hsl(193deg 45% 19%) 80%,
            hsl(187deg 46% 23%) 100%
        );
        color: white;
        border: white solid 2px;
    }
`;
