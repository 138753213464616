import { BrowserRouter, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

import EditProfile from './pages/EditProfile';
import Home from './pages/Home';
import Leagues from './pages/Leagues';
import Profile from './pages/Profile';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import AdminManager from './pages/AdminManager';
import Footer from './components/UIElements/Footer';
import NavBar from './components/Navigation/NavBar';

import GlobalStyles from './utils/GlobalStyles';
import AccessRoute from './components/Navigation/AccessRoute';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

function App() {
    return (
        <BrowserRouter>
            <NavBar />
            <GlobalStyles />
            <ToastContainer />
            <AccessRoute>
                <RoutesWrapper>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/profile" element={<Profile />} />
                    <Route exact path="/sign-up" element={<Signup />} />
                    <Route exact path="/sign-in" element={<Signin />} />
                    <Route exact path="/leagues" element={<Leagues />} />
                    <Route
                        exact
                        path="/edit-profile"
                        element={<EditProfile />}
                    />
                    <Route exact path="/admin" element={<AdminManager />} />
                    <Route
                        exact
                        path="/forgot-password"
                        element={<ForgotPassword />}
                    />
                    <Route
                        exact
                        path="/reset-password/:userId"
                        element={<ResetPassword />}
                    />
                </RoutesWrapper>
            </AccessRoute>
            <Footer />
        </BrowserRouter>
    );
}

export default App;

const RoutesWrapper = styled(Routes)`
    display: flex;
    flex: 1;
`;
