import Toaster from '../plugin/Toaster';
import { redirect } from 'react-router-dom';

export function showBackendError(e) {
    let error;
    const user = localStorage.getItem('user');

    if (e.response.status === 401) {
        localStorage.removeItem('user');
        window.location.reload();
        return;
    } else if (e.response) {
        error = e.response.data.message.split('- ');
    } else if (e.message) {
        error = e.message;
    }

    const message = error[1];

    Toaster.error(message);
}
