import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import PaymentMethod from '../enums/PaymentMethod';

import Logo from '../assets/images/Montreal.png';

import SportsBar from '../components/Sports/SportsBar';

import { bidApi, playerApi, sportApi, teamApi } from '../api';
import useIsMobileDevice from '../hooks/useIsMobileDevice';
import { Utils } from '../utils';
import { PikdUtils } from 'pikd-utils';

const Profile = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const isMobileDevice = useIsMobileDevice();

    const user = JSON.parse(localStorage.getItem('user'));

    const paypalRef = useRef();
    const bankAccountRef = useRef();
    const venmoRef = useRef();
    const zelleRef = useRef();

    const [players, setPlayers] = useState([]);
    const [teams, setTeams] = useState([]);
    const [sports, setSports] = useState([]);
    const [bids, setBids] = useState([]);
    const [limit, setLimit] = useState(5);
    const [player, setPlayer] = useState({});
    const [sport, setSport] = useState({});

    const [selectedPlayer, setSelectedPlayer] = useState('');
    const [selectedTeam, setSelectedTeam] = useState('');
    const [selectedSport, setSelectedSport] = useState('');
    const [amount, setAmount] = useState('');

    useEffect(() => {
        setPlayer(location.state?.player);
        setSelectedPlayer(location.state?.player._id);
        setSelectedSport(location.state?.sport);

        (async () => {
            try {
                await getPlayers();
                await getTeams();
                await getBids();
                await getSports();
                const sportResponse = await sportApi.get(
                    location.state?.player.sport
                );

                setSport(sportResponse.data);
            } catch (e) {
                Utils.showBackendError(e);
            }
        })();

        if (isMobileDevice) {
            setLimit(3);
        }
    }, []);

    useEffect(() => {
        (async () => {
            await getBids();
        })();
    }, [limit]);

    function selectPaymentOption(paymentMethod) {
        switch (paymentMethod) {
            case PaymentMethod.PAYPAL:
                paypalRef.current.checked = true;
                break;
            case PaymentMethod.BANK_ACCOUNT:
                bankAccountRef.current.checked = true;
                break;
            case PaymentMethod.VENMO:
                venmoRef.current.checked = true;
                break;
            case PaymentMethod.ZELLE:
                zelleRef.current.checked = true;
                break;
            default:
                paypalRef.current.checked = false;
                bankAccountRef.current.checked = false;
                venmoRef.current.checked = false;
                zelleRef.current.checked = false;
                break;
        }
    }

    function resetBid(event) {
        if (event) {
            event.preventDefault();
        }

        // selectPaymentOption();
        setAmount('');
        setSelectedTeam('');
        setSelectedPlayer('');
    }

    async function getPlayers() {
        const sportId = selectedSport || location.state?.player.sport;

        try {
            const response = await playerApi.getAll(sportId);
            setPlayers(response.data);
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    async function getPlayersForSport(sportId) {
        try {
            const response = await playerApi.getAll(sportId);

            setPlayers(response.data);
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    async function getTeamsForSport(sportId) {
        try {
            const response = await teamApi.getAll(sportId);

            setTeams(response.data);
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    async function getSports() {
        try {
            const response = await sportApi.getAll();

            setSports(response.data);
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    async function getTeams() {
        const sportId = selectedSport || location.state?.player.sport;
        try {
            // TODO: make a redux logic for the sportId (coming from the selection menu at the top)
            const response = await teamApi.getAll(sportId);

            setTeams(response.data);
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    async function getBids() {
        const playerId = player._id || location.state.player._id;
        const sportId = selectedSport || location.state?.player.sport;

        try {
            const response = await bidApi.getBidsForPlayerAndSport(
                playerId,
                sportId,
                limit
            );

            setBids(response.data);
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    async function placeBid(event) {
        event.preventDefault();
        if (!user) {
            navigate('/sign-in');
        }

        const sportId = selectedSport || location.state?.player.sport;

        try {
            const response = await bidApi.placeBid(
                user._id,
                amount,
                selectedPlayer,
                selectedTeam,
                sportId,
                limit
            );

            localStorage.setItem('user', JSON.stringify(response.data.user));
            setBids(response.data.bids);
        } catch (e) {
            Utils.showBackendError(e);
        } finally {
            resetBid();
        }
    }

    async function onSelectSport(e) {
        setSelectedSport(e.target.value);

        try {
            await getPlayersForSport(e.target.value);
            await getTeamsForSport(e.target.value);
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    async function onSelectPlayer(e) {
        setSelectedPlayer(e.target.value);
        const sportId = selectedSport || location.state?.player.sport;

        try {
            const playerResponse = await playerApi.get(e.target.value);
            const bidsResponse = await bidApi.getBidsForPlayerAndSport(
                playerResponse.data._id,
                sportId,
                limit
            );
            const sportResponse = await sportApi.get(sportId);

            setSport(sportResponse.data);
            setPlayer(playerResponse.data);
            setBids(bidsResponse.data);
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    return (
        <>
            <FanFeatureMain>
                <SportsBar />

                <MakeABid>Make a Bid</MakeABid>

                <TabContainer>
                    {!isMobileDevice && (
                        <Img src={PikdUtils.getImageFromDrive(sport.image)} />
                    )}

                    <PlayerProfile>
                        <Image
                            src={PikdUtils.getImageFromDrive(player.image)}
                        />

                        <PlayerInfo>
                            <PlayerName>{player.name}</PlayerName>
                            <PlayerUFA>
                                {player.ufaType} {player.ufaYear || '2023'}
                            </PlayerUFA>
                        </PlayerInfo>
                    </PlayerProfile>

                    <ProfileLeaderBoard>
                        <Column>
                            <LeaderBoardHeading>
                                <SectionTitle>LEADERBOARD</SectionTitle>
                                <SectionTotalBid>TOTAL BIDS</SectionTotalBid>
                            </LeaderBoardHeading>

                            <BidsContainer>
                                {!bids.length && (
                                    <NoBidsContainer>
                                        <NoBids>No bids</NoBids>
                                    </NoBidsContainer>
                                )}
                                {bids?.map((bid) => {
                                    return (
                                        <BidItem key={bid._id}>
                                            <TeamContainer>
                                                <IconImage
                                                    src={
                                                        PikdUtils.getImageFromDrive(
                                                            bid.image
                                                        ) || Logo
                                                    }
                                                ></IconImage>
                                                <BidTeamContainer>
                                                    <TeamName>
                                                        {bid._id}
                                                    </TeamName>
                                                </BidTeamContainer>
                                            </TeamContainer>

                                            <Bid>
                                                <BidText>{`${bid.totalAmount.toFixed(
                                                    2
                                                )}$`}</BidText>
                                            </Bid>
                                        </BidItem>
                                    );
                                })}
                            </BidsContainer>

                            <LoadMoreContainer>
                                <LoadMoreButton
                                    onClick={async () => setLimit(0)}
                                >
                                    LOAD MORE
                                </LoadMoreButton>
                            </LoadMoreContainer>
                        </Column>

                        <Column1>
                            <MakeBidForm>
                                <FormHeading>
                                    <SectionTitle>MAKE A BID</SectionTitle>
                                </FormHeading>

                                <BidForm>
                                    <SelectItem
                                        placeholder="Select Sport"
                                        onChange={(e) => onSelectSport(e)}
                                        value={selectedSport}
                                    >
                                        <Option value="">SELECT A SPORT</Option>

                                        {sports.map((sport) => {
                                            return (
                                                <Option
                                                    key={sport._id}
                                                    value={sport._id}
                                                >
                                                    {sport.name}
                                                </Option>
                                            );
                                        })}
                                    </SelectItem>

                                    <SelectItem
                                        placeholder="Select Player"
                                        onChange={onSelectPlayer}
                                        value={selectedPlayer}
                                    >
                                        <Option value="">
                                            SELECT A PLAYER
                                        </Option>

                                        {players.map((player) => {
                                            return (
                                                <Option
                                                    key={player._id}
                                                    value={player._id}
                                                >
                                                    {player.name}
                                                </Option>
                                            );
                                        })}
                                    </SelectItem>

                                    <SelectItem
                                        placeHolder="Select Your Team"
                                        onChange={(e) =>
                                            setSelectedTeam(e.target.value)
                                        }
                                        value={selectedTeam}
                                    >
                                        <Option value="">
                                            SELECT YOUR TEAM
                                        </Option>

                                        {teams.map((team) => {
                                            return (
                                                <Option
                                                    key={team._id}
                                                    value={team._id}
                                                >
                                                    {team.name}
                                                </Option>
                                            );
                                        })}
                                    </SelectItem>

                                    <InputNumber
                                        type="text"
                                        placeholder="ENTER BID AMOUNT $"
                                        value={amount}
                                        onChange={(e) =>
                                            setAmount(e.target.value)
                                        }
                                    />

                                    {user && (
                                        <Price>
                                            <Balance>
                                                PIK'D Balance : $
                                                {user.balance.toFixed(2)}
                                            </Balance>
                                        </Price>
                                    )}

                                    <PaymentButtons>
                                        <Button2
                                            value="pay-now"
                                            onClick={placeBid}
                                        >
                                            MAKE A BID
                                        </Button2>
                                    </PaymentButtons>
                                </BidForm>
                            </MakeBidForm>
                        </Column1>
                    </ProfileLeaderBoard>
                </TabContainer>
            </FanFeatureMain>
        </>
    );
};

const FanFeatureMain = styled.section`
    display: flex;
    flex: 1;
    flex-direction: column;

    background: linear-gradient(
        45deg,
        rgba(61, 37, 63, 1) 0%,
        rgba(61, 37, 63, 1) 50%,
        rgba(61, 37, 63, 1) 100%
    );
    padding: 0 20px 20px 20px;

    overflow: hidden;

    @media (max-width: 768px) {
        padding: 0;
    }
`;

const MakeABid = styled.h2`
    display: flex;
    justify-content: center;

    color: #ffffff;
    font-weight: 700;
    font-size: 40px;

    text-transform: uppercase;
    letter-spacing: 2px;

    margin: 30px 0 30px 0;

    @media (max-width: 290px) {
        font-size: 20px;
    }
`;

const TabContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    background-color: white;
    border-radius: 30px;

    margin: 0 20px 0 20px;
    padding: 0 0 20px 0;

    @media (max-width: 768px) {
        margin: 0 15px 15px 15px;
        padding: 0;
    }
`;

const Img = styled.img`
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    width: 100%; /* This ensures that the image stretches to fill the width */
    height: auto; /* This will adjust the height according to the image's aspect ratio */
    max-height: 200px;
    display: block; /* To remove any potential space at the bottom of the image */
`;

const PlayerProfile = styled.div`
    display: flex;
    padding: 0 0 40px 150px;
    margin-top: -60px;
    text-align: left;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: row;

        padding: 20px 20px 20px 10px;
        margin: 0;
    }
`;

const Image = styled.img`
    border-radius: 100px;
    object-fit: contain; /* Ensures the image fits within the dimensions */
    width: 200px;
    height: 200px;

    @media (max-width: 768px) {
        display: flex;
        flex: 1;

        width: 150px;
        height: 150px;
        object-fit: contain; /* Ensures the image fits within the dimensions */
    }
`;

const PlayerInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-top: 75px;

    @media (max-width: 768px) {
        display: flex;
        flex: 2;

        justify-content: center;
        align-items: center;

        margin: 0;
    }
`;

const PlayerUFA = styled.p`
    font-size: 20px;

    @media (max-width: 768px) {
        font-size: 20px;

        margin: 0;
    }
`;

const PlayerName = styled.h3`
    font-size: 40px;

    @media (max-width: 768px) {
        font-size: 25px;
        text-align: center;
    }
`;

const ProfileLeaderBoard = styled.div`
    display: flex;
    flex: 1;

    border-top: 1px solid #b2b2b2;

    @media (max-width: 918px) {
        display: inline-flex;
        flex-direction: column;
    }
`;

const LeaderBoardHeading = styled.div`
    display: flex;
    width: 82%;
    padding: 60px 0 0 20px;

    @media (max-width: 768px) {
        padding: 15px 10px 0 10px;
        width: 100%;
    }
`;

const BidsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 600px;
    overflow: auto;
`;

const NoBidsContainer = styled.div`
    display: flex;
    flex: 1;

    justify-content: center;
    align-items: center;

    padding: 20px 0 20px 0;
`;

const NoBids = styled.p`
    font-size: 20px;
`;

const BidItem = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        display: inline-flex;
    }
`;

const TeamContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: #d3d3d3;

    margin-right: 10px;
    padding: 0 10px 0 60px;
    height: 100px;

    border-radius: 25px;

    @media (max-width: 768px) {
        display: flex;
        flex: 1;

        margin: 10px;
        padding: 0 0 0 10px;

        height: 70px;
    }
`;

const Bid = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
        45deg,
        rgba(61, 37, 63, 1) 0%,
        rgba(19, 32, 41, 1) 50%,
        rgba(32, 81, 87, 1) 100%
    );
    padding: 0 10px 0 10px;
    height: 100px;
    border-radius: 25px;

    @media (max-width: 768px) {
        height: 70px;
    }
`;

const BidText = styled.p`
    font-size: 19px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    @media (max-width: 380px) {
        font-size: 15px;
    }
    @media (max-width: 330px) {
        font-size: 10px;
    }
    @media (max-width: 290px) {
        font-size: 5px;
    }
`;

const IconImage = styled.img`
    display: flex;
    border-radius: 20px;

    width: 60px;
    height: 60px;

    margin: 0 10px 0 0;

    @media (max-width: 768px) {
        width: 40px;
        height: 40px;

        margin: 0;
    }
`;

const TeamName = styled.h4`
    display: flex;
    border-radius: 25px;
    margin-top: 20px;
    margin-left: 10px;
    font-size: 20px;
    color: #00525f;

    text-transform: uppercase;

    @media (max-width: 768px) {
        font-size: 15px;
        text-align: center;
    }
`;

const LoadMoreContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    @media (max-width: 768px) {
        font-size: 15px;
        text-align: center;
        margin: 0;
    }
`;

const LoadMoreButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    height: 60px;
    width: 70%;

    margin: 0 0 0 0;

    background-color: #b7e9ff;
    border-radius: 20px;
    color: #00525f;

    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;

    transition: ease-in-out 0.3s;

    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }

    @media (max-width: 290px) {
        font-size: 10px;
        height: 20px;
    }
`;

const Column = styled.div`
    display: flex;
    flex: 1.5;
    flex-direction: column;

    border-right: 1px solid #b2b2b2;
    padding: 0 50px 0 50px;

    @media (max-width: 768px) {
        display: flex;
        flex: 1;

        padding: 0;
    }
`;

const Column1 = styled.div`
    display: flex;
    flex: 1;

    @media (max-width: 768px) {
        display: flex;
        flex: 1;
        margin: 15px 0 0 0;
    }
`;

const MakeBidForm = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 60px 20px 0 40px;

    @media (max-width: 768px) {
        padding: 10px;
        border-top: 1px solid #b2b2b2;
    }
`;

const FormHeading = styled.div`
    width: 35%;
    margin: 0 0 10px 0;
`;

const SectionTitle = styled.h3`
    margin-bottom: 40px;
    font-size: 30px;

    @media (max-width: 768px) {
        font-size: 20px;
        margin: 0;
    }
`;

const SectionTotalBid = styled.h3`
    text-align: right;
    width: 100%;
    margin-bottom: 40px;
    font-size: 30px;

    @media (max-width: 768px) {
        font-size: 20px;
        margin: 0;
    }
`;

const BidForm = styled.form`
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
`;

const SelectItem = styled.select`
    font-size: 15px;

    height: 80px;
    width: 100%;

    padding-left: 10px;
    margin-bottom: 30px;
    border-radius: 10px;

    background-color: #d3d3d3;
    outline: none;
    border: none;
    color: grey;

    &:hover {
        cursor: pointer;
    }

    @media (max-width: 768px) {
        height: 60px;
        margin-bottom: 10px;
    }
`;

const Option = styled.option`
    color: #00525f;
`;

const InputNumber = styled.input`
    color: black;
    font-size: 15px;
    height: 80px;
    padding-left: 10px;
    border-radius: 10px;
    background-color: #d3d3d3;
    border: none;
    outline: none;
    width: 100%;

    &:hover {
        cursor: pointer;
    }

    @media (max-width: 768px) {
        height: 60px;
    }
`;

const Price = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 30px 0 0 0;

    border-radius: 15px;
    border: 3px solid #008b8b;

    color: #008b8b;

    font-size: 30px;
`;

const Balance = styled.p`
    color: #008b8b;
    text-align: center;
`;

const PaymentButtons = styled.div`
    display: flex;
    justify-content: center;
    padding: 10px;
`;

const Button2 = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    height: 60px;
    width: 70%;

    margin: 20px 0 0 0;

    background-color: #b7e9ff;
    border-radius: 20px;
    color: #00525f;

    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;

    transition: ease-in-out 0.3s;

    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }
`;

const BidTeamContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
`;

export default Profile;
