import Enum from './Enum.js';

export default class HomePageSections extends Enum {
    static get entries() {
        return {
            how_it_works: 'HOW_IT_WORKS',
            top_athletes: 'TOP_ATHLETES',
            select_a_sport: 'SELECT_A_SPORT',
        };
    }

    static get HOW_IT_WORKS() {
        return this.entries.how_it_works;
    }

    static get TOP_ATHLETES() {
        return this.entries.top_athletes;
    }

    static get SELECT_A_SPORT() {
        return this.entries.select_a_sport;
    }
}
