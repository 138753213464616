import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import Banner from '../assets/images/editprofile-backend.jpg';
import ProfileImg from '../assets/images/edit-profile-user.jpg';
import Stripe from '../assets/images/stripe.svg';

import { userApi, stripeApi, playerApi, bidApi } from '../api';
import { usePasswordConfirmed } from '../hooks/usePasswordConfirmed';
import Toaster from '../plugin/Toaster';
import Select from 'react-select';
import useIsMobileDevice from '../hooks/useIsMobileDevice';
import image from '../assets/images/player-img-1.jpg';
import moment from 'moment';
import { Utils } from '../utils';
import { PikdUtils } from 'pikd-utils';

const EditProfile = () => {
    const navigate = useNavigate();
    const isMobileDevice = useIsMobileDevice();

    const memorizedUser = JSON.parse(localStorage.getItem('user'));

    const passwordReinitialization = {
        password: '',
        newPassword: '',
        confirmPassword: '',
    };

    const [user, setUser] = useState(memorizedUser);
    const [newPassword, setNewPassword] = useState(passwordReinitialization);
    const [amount, setAmount] = useState(10);

    const passwordConfirmed = usePasswordConfirmed(
        newPassword.newPassword,
        newPassword.confirmPassword
    );
    const disabledUserUpdate = _.isEqual(user, memorizedUser);
    const disabledPasswordUpdate =
        !passwordConfirmed ||
        !newPassword.password ||
        !newPassword.newPassword ||
        !newPassword.confirmPassword;

    const paymentOptions = [
        { value: 10, label: '10$' },
        { value: 25, label: '25$' },
        { value: 50, label: '50$' },
        { value: 100, label: '100$' },
        { value: 0, label: 'Custom Amount' },
    ];

    const [bids, setBids] = useState([]);

    useEffect(() => {
        if (!user) {
            navigate('/sign-in');
        }

        (async () => {
            await getBidsForUser(user?._id);
        })();
    }, [user]);

    async function getBidsForUser(userId) {
        try {
            const response = await bidApi.getAllBidsForUser(userId);
            setBids(response.data);
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    function setUserForm(payload) {
        setUser({ ...user, ...payload });
    }

    function selectAmount(e) {
        setAmount(e);
    }

    function setNewPasswordForm(payload) {
        setNewPassword({ ...newPassword, ...payload });
    }

    function cancelUpdateUser(event) {
        event.preventDefault();

        setUser(memorizedUser);
    }

    function cancelUpdatePassword(event) {
        event.preventDefault();

        setNewPassword(passwordReinitialization);
    }

    async function updateUser(event) {
        event.preventDefault();

        if (!Utils.isEmailValid(user.email)) {
            Toaster.error('Please enter a valid email');
            return;
        }

        try {
            const response = await userApi.updateUser(memorizedUser._id, user);

            localStorage.setItem('user', JSON.stringify(response.data));
            setUser(response.data);
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    async function updatePassword(event) {
        event.preventDefault();

        if (!Utils.isPasswordValid(newPassword.newPassword)) {
            Toaster.error(
                'Please make sure your password contains at least 1 uppercase letter, 1 lowercase letter, 1 special character and 1 number'
            );
            return;
        }

        if (newPassword.newPassword !== newPassword.confirmPassword) {
            Toaster.error(
                'Make sure that your password and confirmation match'
            );
            return;
        }

        try {
            await userApi.updatePassword(
                memorizedUser._id,
                newPassword.password,
                newPassword.newPassword
            );

            setNewPassword(passwordReinitialization);
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    async function addToBalance(event) {
        event.preventDefault();

        try {
            const stripe_r = await stripeApi.createSession(
                memorizedUser._id,
                amount
            );

            window.location.replace(stripe_r.data.url);
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    const formatOptionLabel = ({ label }) => (
        <InputNumberValue>
            <p>{label}</p>
        </InputNumberValue>
    );

    if (!user) {
        return;
    }

    return (
        <>
            <FanFeatureMain>
                <FanProfileMainOuter>
                    {!isMobileDevice && (
                        <BannerImage
                            src={memorizedUser?.background || Banner}
                        />
                    )}

                    <FanProfileInfo>
                        <FanProfileUserImg>
                            <ProfileImage
                                src={memorizedUser?.image || ProfileImg}
                            />
                        </FanProfileUserImg>

                        <ProfileName>
                            <Name>{`${memorizedUser?.first_name} ${memorizedUser?.last_name}`}</Name>
                            <Email>{memorizedUser?.email}</Email>
                        </ProfileName>

                        <BalanceContainer>
                            <Balance>
                                PIK'D Balance: $
                                {memorizedUser?.balance.toFixed(2)}
                            </Balance>
                        </BalanceContainer>
                    </FanProfileInfo>

                    <ProfileLeaderBid>
                        <SectionTitle>Add Balance</SectionTitle>

                        <BidAmount>
                            <InputNumber
                                className="basic-single"
                                options={paymentOptions}
                                defaultValue={paymentOptions[0]}
                                isSearchable={false}
                                selectOption={amount}
                                style={{ width: '100px' }}
                                onChange={(e) => selectAmount(e.value)}
                                formatOptionLabel={formatOptionLabel}
                            />

                            <EditProfileButton>
                                <Button1
                                    onClick={(event) => addToBalance(event)}
                                >
                                    Add
                                </Button1>
                            </EditProfileButton>

                            <PaymentLogo src={Stripe} />
                        </BidAmount>

                        <SectionTitle>Personal Info</SectionTitle>

                        <Form>
                            <SplitDiv>
                                <InputField>
                                    <Label>First Name</Label>
                                    <Input
                                        type="text"
                                        placeholder="Enter First Name"
                                        value={user?.first_name}
                                        onChange={(e) =>
                                            setUserForm({
                                                first_name: e.target.value,
                                            })
                                        }
                                    />
                                </InputField>

                                <InputField>
                                    <Label>Last Name</Label>
                                    <Input
                                        type="text"
                                        placeholder="Enter Last Name"
                                        value={user?.last_name}
                                        onChange={(e) =>
                                            setUserForm({
                                                last_name: e.target.value,
                                            })
                                        }
                                    />
                                </InputField>

                                <InputField>
                                    <Label>Enter Email</Label>
                                    <Input
                                        type="email"
                                        placeholder="Enter Email"
                                        value={user?.email}
                                        onChange={(e) =>
                                            setUserForm({
                                                email: e.target.value,
                                            })
                                        }
                                    />
                                </InputField>
                            </SplitDiv>

                            <SplitDiv>
                                <InputField>
                                    <Label>Phone Number</Label>
                                    <Input
                                        type="phone"
                                        placeholder="Phone Number"
                                        value={user?.phone_number}
                                        onChange={(e) =>
                                            setUserForm({
                                                phone_number: e.target.value,
                                            })
                                        }
                                    />
                                </InputField>

                                <InputField>
                                    <Label>Birthday</Label>
                                    <Input
                                        type="text"
                                        placeholder="N/A"
                                        value={PikdUtils.readableDate(
                                            user?.birth_date
                                        )}
                                        onChange={(e) =>
                                            setUserForm({
                                                city: e.target.value,
                                            })
                                        }
                                    />
                                </InputField>

                                <InputField1>
                                    <Label>Street Address</Label>
                                    <Input
                                        type="address"
                                        placeholder="House 123, Montreal, Quebec, Canada"
                                        value={user?.address}
                                        onChange={(e) =>
                                            setUserForm({
                                                address: e.target.value,
                                            })
                                        }
                                    />
                                </InputField1>
                            </SplitDiv>

                            <SplitDiv>
                                <InputField>
                                    <Label>City</Label>
                                    <Input
                                        type="text"
                                        placeholder="City"
                                        value={user?.city}
                                        onChange={(e) =>
                                            setUserForm({
                                                city: e.target.value,
                                            })
                                        }
                                    />
                                </InputField>

                                <InputField>
                                    <Label>Postal Code</Label>
                                    <Input
                                        type="text"
                                        placeholder="54000"
                                        value={user?.postal_code}
                                        onChange={(e) =>
                                            setUserForm({
                                                postal_code: e.target.value,
                                            })
                                        }
                                    />
                                </InputField>
                            </SplitDiv>

                            <UpdateProfileButton>
                                <Button
                                    onClick={(event) => cancelUpdateUser(event)}
                                >
                                    Cancel
                                </Button>
                                <Button1
                                    disabled={disabledUserUpdate}
                                    onClick={(event) => updateUser(event)}
                                >
                                    Update
                                </Button1>
                            </UpdateProfileButton>
                        </Form>

                        <Form>
                            <SectionTitle>Change Password</SectionTitle>

                            <SplitDiv>
                                <InputField>
                                    <Label>Current Password</Label>
                                    <Input
                                        type="password"
                                        placeholder="**********"
                                        value={newPassword.password}
                                        onChange={(e) =>
                                            setNewPasswordForm({
                                                password: e.target.value,
                                            })
                                        }
                                    />
                                </InputField>

                                <InputField>
                                    <Label>New Password</Label>
                                    <Input
                                        type="password"
                                        placeholder="**********"
                                        value={newPassword.newPassword}
                                        onChange={(e) =>
                                            setNewPasswordForm({
                                                newPassword: e.target.value,
                                            })
                                        }
                                    />
                                </InputField>

                                <InputField>
                                    <Label>Confirm Password</Label>
                                    <Input
                                        type="password"
                                        placeholder="*********"
                                        value={newPassword.confirmPassword}
                                        onChange={(e) =>
                                            setNewPasswordForm({
                                                confirmPassword: e.target.value,
                                            })
                                        }
                                    />
                                </InputField>
                            </SplitDiv>

                            <UpdateProfileButton>
                                {/*TODO: UI for wrong password (error handling)*/}
                                <Button
                                    onClick={(event) =>
                                        cancelUpdatePassword(event)
                                    }
                                >
                                    Cancel
                                </Button>
                                <Button1
                                    disabled={disabledPasswordUpdate}
                                    onClick={(event) => updatePassword(event)}
                                >
                                    Update
                                </Button1>
                            </UpdateProfileButton>
                        </Form>

                        <SectionTitle>Bid History</SectionTitle>

                        <TabContainer>
                            {bids.map((bid) => {
                                const date = moment(bid.createdAt).format(
                                    'YYYY-MM-DD'
                                );

                                return (
                                    <FanContent key={bid?._id}>
                                        <Box>
                                            <PlayerInfo>
                                                <PlayerNameContainer>
                                                    <PlayersImage
                                                        src={
                                                            PikdUtils.getImageFromDrive(
                                                                bid?.playerImage
                                                            ) || image
                                                        }
                                                        alt="hockey player"
                                                    />
                                                    <PlayerName>
                                                        {bid?.playerName}
                                                    </PlayerName>
                                                </PlayerNameContainer>
                                            </PlayerInfo>
                                        </Box>

                                        <Box>
                                            <PlayerInfo>
                                                <PlayerNameContainer>
                                                    <PlayersImage
                                                        src={
                                                            PikdUtils.getImageFromDrive(
                                                                bid?.teamImage
                                                            ) || image
                                                        }
                                                        alt="hockey player"
                                                    />
                                                    <PlayerName>
                                                        {bid?.teamName}
                                                    </PlayerName>
                                                </PlayerNameContainer>
                                            </PlayerInfo>
                                        </Box>

                                        <Box>
                                            <TabPlayerYear>
                                                <Span>${bid?.amount}</Span>
                                            </TabPlayerYear>
                                        </Box>

                                        <Box>
                                            <TabPlayerYear>
                                                <Span>{date}</Span>
                                            </TabPlayerYear>
                                        </Box>
                                        <Box>
                                            <TabPlayerYear>
                                                <Span
                                                    style={{
                                                        color: bid.archive
                                                            ? 'red'
                                                            : 'green',
                                                    }}
                                                >
                                                    {bid.archive
                                                        ? 'Bid Voided'
                                                        : 'Bid Active'}
                                                </Span>
                                            </TabPlayerYear>
                                        </Box>
                                    </FanContent>
                                );
                            })}
                        </TabContainer>
                    </ProfileLeaderBid>
                </FanProfileMainOuter>
            </FanFeatureMain>
        </>
    );
};

export default EditProfile;

const FanFeatureMain = styled.div`
    background: linear-gradient(
        45deg,
        rgba(61, 37, 63, 1) 0%,
        rgba(19, 32, 41, 1) 50%,
        rgba(32, 81, 87, 1) 100%
    );
    text-align: center;
    height: 100%;
    padding: 100px 0 100px 0;

    @media (max-width: 768px) {
        padding: 20px 10px 20px 10px;
    }
`;

const FanProfileMainOuter = styled.div`
    margin: 0 auto;
    max-width: 92vw;
    background-color: #fff;
    border-radius: 25px;
    padding-bottom: 1px;

    @media (max-width: 768px) {
        padding: 10px 0 0 0;
    }
`;

const BannerImage = styled.img`
    width: 100%;
    border-radius: 25px 25px 0 0;
    height: 300px;
`;

const FanProfileInfo = styled.div`
    display: flex;
    flex: 1;
`;

const FanProfileUserImg = styled.div`
    display: flex;
    flex: 1;
    margin: -100px 0 0 100px;

    @media (max-width: 768px) {
        margin: 0;
    }
`;

const ProfileImage = styled.img`
    border-radius: 50%;
    width: 70%;
    height: 100%;

    @media (max-width: 768px) {
        width: 100px;
        height: 100px;
    }
`;

const ProfileName = styled.div`
    display: flex;
    flex: 3;
    flex-direction: column;

    justify-content: center;
    align-items: flex-start;

    text-align: start;

    @media (max-width: 768px) {
        margin: 0;
    }
`;

const Name = styled.h3`
    font-weight: bold;
    font-size: 30px;
    color: #008b8b;

    @media (max-width: 768px) {
        font-size: 20px;
        margin: 0 0 5px 5px;
    }
`;

const Email = styled.p`
    color: #008b8b;
    font-size: 20px;

    @media (max-width: 768px) {
        font-size: 12px;
        margin: 0 0 0 5px;
    }
`;

const BalanceContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`;

const Balance = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 10px;

    border: 3px solid #008b8b;
    border-radius: 15px;

    font-size: 30px;
    color: #008b8b;

    @media (max-width: 768px) {
        font-size: 20px;
        height: 90%;
        margin: 10px;
    }
`;
const ProfileLeaderBid = styled.div`
    margin-top: 50px;

    @media (max-width: 768px) {
        margin: 30px 0 0 0;
    }
`;

const Form = styled.div`
    width: 100%;
`;

const Label = styled.label`
    font-size: 15px;
    line-height: 12px;
    color: #adb0b1;

    text-align: left;
    margin: 0 0 5px 0;

    @media (max-width: 500px) {
        font-size: 10px;
    }
`;

const Input = styled.input`
    background-color: #f5f5f5;
    font-size: 20px;
    outline: none;
    border: 0;
    width: 100%;

    line-height: 10px;
    color: #475063;

    text-align: left;

    @media (max-width: 768px) {
        font-size: 15px;
    }
`;

const InputField = styled.div`
    border-radius: 15px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    background-color: #f5f5f5;
    width: 28%;

    @media (max-width: 768px) {
        width: 90%;
        margin: 0 0 10px 0;
    }
`;

const SplitDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: 0 0 0 50px;
    margin: 0 0 40px 0;

    @media (max-width: 768px) {
        padding: 0;
        margin: 0 0 20px 0;
        flex-direction: column;
        transition: 2s;
    }
`;

const InputField1 = styled.div`
    display: flex;
    flex: 1;

    flex-direction: column;

    @media (max-width: 768px) {
        flex: none;
        width: 90%;
        margin: 0;
    }
`;

const EditProfileButton = styled.div`
    display: flex;
    justify-content: flex-start;

    margin-right: 50px;

    @media (max-width: 768px) {
        flex-direction: column;
        margin: 10px 10px 30px 10px;
    }
`;

const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eef5f8;
    color: #babec0;
    border: none;
    padding: 20px 40px;
    text-transform: uppercase;
    border-radius: 25px;
    font-size: 20px;
    margin-right: 10px;

    &:hover {
        cursor: pointer;
        font-weight: bolder;
        background-color: #f08080;
        transition: 0.5ms;
    }

    @media (max-width: 768px) {
        font-size: 15px;

        margin: 0 0 10px 0;
        height: 20px;
    }
`;
const Button1 = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    height: 60px;
    width: 70%;

    margin: 0 0 0 0;

    background-color: #b7e9ff;
    border-radius: 20px;
    color: #00525f;

    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;

    transition: ease-in-out 0.3s;

    min-width: 200px;
    max-width: 300px;

    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }
`;

const SectionTitle = styled.h3`
    margin-bottom: 45px;
    text-transform: uppercase;
    color: #3a2e42;
    font-size: 30px;
    margin-left: 50px;

    text-align: left;

    @media (max-width: 768px) {
        font-size: 20px;
        margin: 0 0 20px 10px;
    }
`;

const PaymentLogo = styled.img`
    width: 10%;

    @media (max-width: 768px) {
        width: 40%;
    }
`;

const BidAmount = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 45px;

    @media (max-width: 768px) {
        margin: 0 0 15px 45px;
    }
`;

const InputNumber = styled(Select)`
    display: flex;

    border-radius: 10px;
    outline: none;
    margin: 0 50px 0 0;

    @media (max-width: 768px) {
        margin: 0 20px 0 0;
    }
`;

const InputNumberValue = styled.div`
    display: flex;
    width: 200px;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        width: 100px;
    }
`;

const TabContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
`;

const FanContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 15px;
    background-color: white;
    margin-bottom: 15px;
    width: 85%;

    @media (min-width: 720px) {
        height: 100px;
    }

    @media (max-width: 720px) {
        flex-direction: column;
        border-radius: 15px;
        background-color: white;
        margin-bottom: 20px;
        max-height: 1200px;

        padding: 10px;

        width: 90%;
    }
`;

const Box = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    border-right: 1px solid rgb(192, 192, 192);
    height: 75%;

    @media (max-width: 768px) {
        display: inline-flex;
        flex-direction: column;

        height: 100%;
        width: 100%;
    }
`;

const PlayerInfo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 768px) {
        margin: 0 0 0 15px;
    }

    flex: 1;
`;

const PlayerNameContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;

    margin: 0 0 0 15px;
`;

const PlayersImage = styled.img`
    border-radius: 22px;

    width: 22%;
    height: 30%;
`;

const PlayerName = styled.h3`
    font-size: 20px;
    text-align: left;

    @media (min-width: 768px) {
        width: 50%;
    }
`;

const TabPlayerYear = styled.div`
    display: flex;
    justify-content: flex-end;

    @media (max-width: 768px) {
        margin: 15px 0 10px 0;
    }
`;

const Span = styled.span`
    font-size: 25px;

    font-weight: 500;
    color: #60929c;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

const UpdateProfileButton = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    padding-right: 50px;
`;
