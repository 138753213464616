import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import image from '../assets/images/player-img-1.jpg';

import SportsBar from '../components/Sports/SportsBar';

import { playerApi } from '../api';
import { Utils } from '../utils';
import { PikdUtils } from 'pikd-utils';

const Leagues = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [players, setPlayers] = useState([]);

    useEffect(() => {
        // Selects hockey by default if no sportId is passed
        const sportId = location.state?.section || '634218d8581eb448b42e815f';

        (async () => {
            await getPlayersFromSport(sportId);
        })();
    }, [location]);

    async function getPlayersFromSport(sportId) {
        try {
            const response = await playerApi.getAllAndBids(sportId);
            setPlayers(response.data);
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    return (
        <>
            <FanFeatureMain>
                <FanPlayerTabsMain>
                    <SportsBar />

                    <FanTabHeading>SELECT A PLAYER</FanTabHeading>

                    <TabContainer>
                        {players.map((player) => {
                            return (
                                <FanContent key={player?._id}>
                                    <Box>
                                        <PlayerInfo>
                                            <PlayersImage
                                                src={
                                                    PikdUtils.getImageFromDrive(
                                                        player.image
                                                    ) || image
                                                }
                                                alt="hockey player"
                                            />

                                            <PlayerNameContainer>
                                                <PlayerName>
                                                    {player?.name}
                                                </PlayerName>
                                            </PlayerNameContainer>
                                        </PlayerInfo>
                                    </Box>

                                    <Box>
                                        <TabPlayerYear>
                                            <Span>
                                                {player?.ufaType}{' '}
                                                {player?.ufaYear
                                                    ? player.ufaYear
                                                    : '2023'}
                                            </Span>
                                        </TabPlayerYear>
                                    </Box>

                                    <Box>
                                        <TabPlayerYear>
                                            <Span>
                                                TOTAL FAN BID: $
                                                {player?.totalBids
                                                    ? player.totalBids.toFixed(
                                                          2
                                                      )
                                                    : `0.00`}
                                            </Span>
                                        </TabPlayerYear>
                                    </Box>

                                    <Box>
                                        <TabPLayer>
                                            <SelectPlayerButton
                                                onClick={() =>
                                                    navigate('/profile', {
                                                        state: {
                                                            player: player,
                                                            sport: player.sport,
                                                        },
                                                    })
                                                }
                                            >
                                                Select Player
                                            </SelectPlayerButton>
                                        </TabPLayer>
                                    </Box>
                                </FanContent>
                            );
                        })}
                    </TabContainer>
                </FanPlayerTabsMain>
            </FanFeatureMain>
        </>
    );
};

export default Leagues;

const FanFeatureMain = styled.section`
    background: linear-gradient(
        45deg,
        rgba(61, 37, 63, 1) 0%,
        rgba(61, 37, 63, 1) 50%,
        rgba(61, 37, 63, 1) 100%
    );
    text-align: center;
    min-height: 90vh;
`;

const FanPlayerTabsMain = styled.div`
    padding: 20px 0 0 0;
`;

const FanTabHeading = styled.div`
    display: flex;
    justify-content: center;

    color: #ffffff;
    font-weight: 700;
    font-size: 40px;

    text-transform: uppercase;
    letter-spacing: 2px;

    margin: 30px 0 30px 0;

    @media (max-width: 768px) {
        font-size: 30px;
    }
`;

const TabContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: linear-gradient(
        45deg,
        rgba(61, 37, 63, 1) 0%,
        rgba(61, 37, 63, 1) 50%,
        rgba(61, 37, 63, 1) 100%
    );
    height: 100%;
`;

const FanContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 15px;
    background-color: white;
    margin-bottom: 15px;
    width: 65%;

    @media (min-width: 720px) {
        height: 100px;
    }

    @media (max-width: 720px) {
        flex-direction: column;
        border-radius: 15px;
        background-color: white;
        margin-bottom: 20px;
        max-height: 1200px;

        padding: 10px;

        width: 90%;
    }
`;

const Box = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    border-right: 1px solid rgb(192, 192, 192);
    height: 50%;

    @media (max-width: 768px) {
        display: inline-flex;
        flex-direction: column;

        height: 100%;
        width: 100%;
    }
`;

const PlayerInfo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 768px) {
        margin: 0 0 0 15px;
    }

    flex: 1;
`;

const PlayersImage = styled.img`
    border-radius: 22px;
    object-fit: cover; /* Ensures the image fits within the dimensions */

    width: 20%; /* Reduced from 30% */
    height: 35%; /* Reduced from 40% */
`;

const PlayerNameContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-start;

    margin: 0 0 0 15px;
`;

const PlayerName = styled.h3`
    font-size: 20px;
    text-align: left;

    @media (min-width: 768px) {
        width: 50%;
    }
`;

const TabPlayerYear = styled.div`
    display: flex;
    justify-content: flex-end;

    @media (max-width: 768px) {
        margin: 15px 0 10px 0;
    }
`;

const Span = styled.span`
    font-size: 25px;

    font-weight: 500;
    color: #60929c;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

const TabPLayer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
`;

const SelectPlayerButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    height: 60px;
    width: 70%;

    margin: 0 0 0 0;

    background-color: #b7e9ff;
    border-radius: 20px;
    color: #00525f;

    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;

    transition: ease-in-out 0.3s;

    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }
`;
