import React from 'react';
import styled from 'styled-components';
import { useValidatedEmail } from '../hooks/InputValidation/useValidatedEmail';
import Toaster from '../plugin/Toaster';
import { mailApi } from '../api';
import { Utils } from '../utils';

export default function ForgotPassword() {
    const [email, setEmail, validated] = useValidatedEmail();

    async function sendForgotPassword(event) {
        event.preventDefault();

        if (!validated) {
            Toaster.warning('Please enter a valid email address');

            return;
        }

        const mailData = {
            email,
            redirectionLink: `${process.env.REACT_APP_FRONTEND_URL}/reset-password/`,
        };

        try {
            await mailApi.forgotPassword(
                mailData.email,
                mailData.redirectionLink
            );
            Toaster.success(
                'Please check your mailbox to reset your password. Make sure that you also check your junk box. If you did not receive it, you are welcome to try again.'
            );
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    return (
        <SectionFanFeatureMain>
            <FanForumOuter>
                <ForgotPasswordTitle>Forgot Password</ForgotPasswordTitle>
                <Request>Enter your email to reset your password</Request>

                <ForgotPasswordTitleForm>
                    <InputField>
                        <Label>Email or username</Label>
                        <Input
                            type="email"
                            placeholder="example@gmail.com"
                            onChange={(e) => setEmail(e.target.value)}
                        ></Input>
                    </InputField>

                    <Button onClick={(event) => sendForgotPassword(event)}>
                        SEND EMAIL
                    </Button>
                </ForgotPasswordTitleForm>
            </FanForumOuter>
        </SectionFanFeatureMain>
    );
}

const SectionFanFeatureMain = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(
        45deg,
        rgba(61, 37, 63, 1) 0%,
        rgba(19, 32, 41, 1) 50%,
        rgba(32, 81, 87, 1) 100%
    );
    height: 70vh;
`;

const FanForumOuter = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    padding: 50px;
    margin: 100px 10px 100px 10px;

    width: 30%;

    background-image: linear-gradient(
        240deg,
        hsl(295deg 26% 20%) 0%,
        hsl(242deg 23% 19%) 20%,
        hsl(212deg 37% 14%) 40%,
        hsl(200deg 40% 14%) 60%,
        hsl(193deg 45% 19%) 80%,
        hsl(187deg 46% 23%) 100%
    );

    border-radius: 25px;

    @media (max-width: 768px) {
        width: 90%;
        margin: 20px 10px 20px 10px;
    }
`;

const ForgotPasswordTitle = styled.h2`
    text-transform: uppercase;
    font-family: 'Lucida Grande', serif;
    letter-spacing: 2px;
    color: #adb9d8;
    font-size: 30px;
    font-weight: 400;
    margin: 0;
    text-align: center;
`;

const Request = styled.p`
    font-size: 16px;
    color: #adb9d8;
    font-family: 'avenir_lt_std55_roman', serif;
    margin-top: 20px;
    text-align: center;
    text-transform: uppercase;
`;

const ForgotPasswordTitleForm = styled.form`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
`;

const InputField = styled.div`
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    border: 0;
    height: 45px;
    max-width: 481px;
    width: 100%;
    margin-top: 10px;
    padding: 10px 20px;
`;

const Label = styled.label`
    font-size: 12px;
    line-height: 12px;
    color: #adb0b1;
    font-family: 'avenir_lt_std55_roman', serif;
    text-align: left;
`;

const Input = styled.input`
    outline: none;
    border: 0;
    font-size: 15px;
    line-height: 20px;
    color: #475063;
    font-family: 'avenir_lt_std55_roman', serif;
    text-align: left;
`;

const Button = styled.button`
    background-color: #b7e9ff;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    font-family: 'avenir_lt_std95_black', serif;
    height: 55px;
    border-radius: 15px;
    margin-top: 20px;
    transition: 0.5s ease;
    border: 2px solid transparent;

    &:hover {
        cursor: pointer;
        background-image: linear-gradient(
            240deg,
            hsl(295deg 26% 20%) 0%,
            hsl(242deg 23% 19%) 20%,
            hsl(212deg 37% 14%) 40%,
            hsl(200deg 40% 14%) 60%,
            hsl(193deg 45% 19%) 80%,
            hsl(187deg 46% 23%) 100%
        );
        color: white;
        border: white solid 2px;
    }
`;
