import api from './api.js';

export default {
    async add(team) {
        return api.post(`/team/add`, team);
    },

    async getAll(sportId) {
        return api.get(`/team/get-all/${sportId}`);
    },
};
