import Enum from './Enum.js';

export default class ExternalUrl extends Enum {
    static get entries() {
        return {
            privacy_policy:
                'https://app.termly.io/document/privacy-policy/ba65d72d-98a8-4e89-9a6f-4fcc63a56fb6',
            terms_and_conditions:
                'https://app.termly.io/document/terms-of-service/ba95c4b7-a0f3-46c4-a1ef-732c67b6ab47',
            twitter_account: 'https://twitter.com/PIKDlive',
            instagram_account: 'https://www.instagram.com/pikd_live/',
        };
    }

    static get PRIVACY_POLICY() {
        return this.entries.privacy_policy;
    }

    static get TERMS_AND_CONDITIONS() {
        return this.entries.terms_and_conditions;
    }

    static get TWITTER_ACCOUNT() {
        return this.entries.twitter_account;
    }

    static get INSTAGRAM_ACCOUNT() {
        return this.entries.instagram_account;
    }
}
