import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { sportApi } from '../../api';
import { Utils } from '../../utils';

const SportsBar = () => {
    const navigate = useNavigate();

    const [sports, setSports] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const response = await sportApi.getAll();

                setSports(
                    response.data.filter((sport) => sport.status === 'ACTIVE')
                );
            } catch (e) {
                Utils.showBackendError(e);
            }
        })();
    }, []);

    function redirect(url, section) {
        navigate(url, {
            state: {
                section: section,
            },
        });
    }

    return (
        <TabsSwipeWrapper>
            {sports.map((sport) => (
                <ListItems
                    key={sport._id}
                    value={sport._id}
                    onClick={() => redirect(`/leagues`, sport._id)}
                >
                    {sport.name}
                </ListItems>
            ))}
        </TabsSwipeWrapper>
    );
};

const TabsSwipeWrapper = styled.ul`
    transform: translate3d(0px, 0px, 0px);
    display: flex;
    vertical-align: top;
    position: relative;
    z-index: 10;
    margin: 25px 0 0;
    list-style: none;
    -ms-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    justify-content: center;
    overflow: auto;

    @media (max-width: 768px) {
        justify-content: flex-start;
        padding-left: 10px;
    }
`;

const ListItems = styled.li`
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 25px;
    padding: 20px;
    width: 277.667px;
    margin-right: 15px;
    transition: all 0.3s ease;
    border: 1px solid #b7e9ff;

    &:hover {
        color: #15262f;
        cursor: pointer;
        background-color: #b7e9ff;
    }
`;

export default SportsBar;
