import axios from 'axios';
import Toaster from '../plugin/Toaster';

const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    credentials: 'include',
});

api.interceptors.response.use(
    (config) => config,
    (error) => {
        return Promise.reject(error);
    }
);

export default api;
