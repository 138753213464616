import api from './api.js';

export default {
    async forgotPassword(email, redirectionLink) {
        return api.post(`/mail/forgot-password`, {
            to: email,
            redirectionLink,
        });
    },
};
