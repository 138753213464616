import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { bidApi, playerApi, sportApi, teamApi } from '../api';
import image from '../assets/images/player-img-1.jpg';
import moment from 'moment';
import { Utils } from '../utils';

const AdminManager = () => {
    const user = JSON.parse(localStorage.getItem('user'));

    const [sports, setSports] = useState([]);
    const [players, setPlayers] = useState([]);
    const [teams, setTeams] = useState([]);

    const [sportName, setSportName] = useState('');
    const [sportImage, setSportImage] = useState('');

    const [teamName, setTeamName] = useState('');
    const [teamImage, setTeamImage] = useState('');
    const [teamSport, setTeamSport] = useState('');

    const [playerName, setPlayerName] = useState('');
    const [playerImage, setPlayerImage] = useState('');
    const [playerUFA, setPlayerUFA] = useState('');
    const [playerUFAType, setPlayerUFAType] = useState('');
    const [playerSport, setPlayerSport] = useState('');
    const [isPlayerFeatured, setIsPlayerFeatured] = useState(false);

    const [bidSport, setBidSport] = useState('');
    const [bidPlayer, setBidPlayer] = useState('');
    const [bidTeam, setBidTeam] = useState('');
    const [bidAmount, setBidAmount] = useState('');

    const [bids, setBids] = useState([]);

    useEffect(() => {
        (async () => {
            await getAllSports();
            await getAllBids();
        })();
    }, []);

    async function addSport(e) {
        e.preventDefault();
        try {
            await sportApi.add({
                name: sportName,
                image: sportImage,
            });
            await getAllSports();
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    async function getAllSports() {
        try {
            let r = await sportApi.getAll();
            setSports(r.data);
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    async function getAllBids() {
        try {
            const response = await bidApi.getAllBids();
            setBids(response.data);
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    async function getAllPlayersForSport(bidSport) {
        try {
            let r = await playerApi.getAll(bidSport);
            setPlayers(r.data);
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    async function getAllTeamsForSport(bidSport) {
        try {
            let r = await teamApi.getAll(bidSport);
            setTeams(r.data);
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    async function addTeam(e) {
        e.preventDefault();
        try {
            await teamApi.add({
                name: teamName,
                image: teamImage,
                sport: teamSport,
            });
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    async function clearFields(e) {
        setSportImage('');
        setSportName('');

        setPlayerName('');
        setPlayerImage('');

        setTeamName('');
        setTeamImage('');

        setBidAmount('');
    }

    async function addPlayer(e) {
        e.preventDefault();
        try {
            await playerApi.add({
                name: playerName,
                image: playerImage,
                ufaYear: playerUFA,
                ufaType: playerUFAType,
                sport: playerSport,
                isFeatured: isPlayerFeatured,
            });
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    async function addBid(e) {
        e.preventDefault();
        try {
            await bidApi.placeBid(
                user._id,
                bidAmount,
                bidPlayer,
                bidTeam,
                bidSport
            );
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    async function refundUser(e, bid) {
        try {
            await bidApi.refundBid(bid);
        } catch (e) {
            Utils.showBackendError(e);
        }
        await getAllBids();
    }

    return (
        <>
            <SectionFanFeatureMain>
                <FanSignInForm>
                    <FanFormOuter>
                        <Create>Admin Panel</Create>

                        <FormDiv>
                            <Columns>
                                <Enter>Add Sport</Enter>
                                <SplitDiv>
                                    <div>
                                        <InputField>
                                            <Label>Sport Name</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                                value={sportName}
                                                onChange={(e) =>
                                                    setSportName(e.target.value)
                                                }
                                            />
                                        </InputField>
                                    </div>
                                    <div>
                                        <InputField>
                                            <Label>Image Url</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                                value={sportImage}
                                                onChange={(e) =>
                                                    setSportImage(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </InputField>
                                    </div>
                                </SplitDiv>
                                <Button
                                    onClick={(e) => {
                                        addSport(e);
                                        clearFields();
                                    }}
                                >
                                    Add Sport
                                </Button>

                                <Enter>Add Team</Enter>
                                <SplitDiv>
                                    <div>
                                        <InputField>
                                            <Label>Team Name</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                                value={teamName}
                                                onChange={(e) =>
                                                    setTeamName(e.target.value)
                                                }
                                            />
                                        </InputField>
                                    </div>
                                    <div>
                                        <InputField>
                                            <Label>Image Url</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                                value={teamImage}
                                                onChange={(e) =>
                                                    setTeamImage(e.target.value)
                                                }
                                            />
                                        </InputField>
                                    </div>
                                </SplitDiv>
                                <InputField1
                                    onChange={(e) =>
                                        setTeamSport(e.target.value)
                                    }
                                >
                                    <Label>Sport</Label>
                                    <select>
                                        <option value="" />
                                        {sports.map((sport) => {
                                            return (
                                                <option
                                                    key={sport._id}
                                                    value={sport._id}
                                                >
                                                    {sport.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </InputField1>
                                <Button
                                    onClick={(e) => {
                                        addTeam(e);
                                        clearFields();
                                    }}
                                >
                                    Add Team
                                </Button>

                                <Enter>Add Player</Enter>
                                <SplitDiv>
                                    <div>
                                        <InputField>
                                            <Label>Player Name</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                                value={playerName}
                                                onChange={(e) =>
                                                    setPlayerName(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </InputField>
                                    </div>
                                    <div>
                                        <InputField>
                                            <Label>Image Url</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                                value={playerImage}
                                                onChange={(e) =>
                                                    setPlayerImage(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </InputField>
                                    </div>
                                </SplitDiv>

                                <SplitDiv>
                                    <FanSignCheck>
                                        <div>
                                            <InputCheckBox
                                                type="checkbox"
                                                id="remember"
                                                name="remember"
                                                value={isPlayerFeatured}
                                                onChange={() =>
                                                    setIsPlayerFeatured(
                                                        !isPlayerFeatured
                                                    )
                                                }
                                            />
                                            <LabelCheck>Is featured</LabelCheck>
                                        </div>
                                    </FanSignCheck>

                                    <InputField>
                                        <Label>FA Type</Label>
                                        <Input
                                            type="text"
                                            placeholder=""
                                            value={playerUFAType}
                                            onChange={(e) =>
                                                setPlayerUFAType(e.target.value)
                                            }
                                        />
                                    </InputField>

                                    <InputField>
                                        <Label>UFA Year</Label>
                                        <Input
                                            type="text"
                                            placeholder=""
                                            value={playerUFA}
                                            onChange={(e) =>
                                                setPlayerUFA(e.target.value)
                                            }
                                        />
                                    </InputField>
                                </SplitDiv>

                                <InputField1
                                    onChange={(e) =>
                                        setPlayerSport(e.target.value)
                                    }
                                >
                                    <Label>Sport</Label>
                                    <select>
                                        <option value="" />
                                        {sports.map((sport) => {
                                            return (
                                                <option
                                                    key={sport._id}
                                                    value={sport._id}
                                                >
                                                    {sport.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </InputField1>
                                <Button
                                    onClick={(e) => {
                                        addPlayer(e);
                                        clearFields();
                                    }}
                                >
                                    Add Player
                                </Button>

                                <Enter>Add Bid</Enter>
                                <InputField1
                                    onChange={async (e) => {
                                        setBidSport(e.target.value);
                                        await getAllPlayersForSport(
                                            e.target.value
                                        );
                                        await getAllTeamsForSport(
                                            e.target.value
                                        );
                                    }}
                                >
                                    <Label>Sport</Label>
                                    <select>
                                        <option value="" />
                                        {sports.map((sport) => {
                                            return (
                                                <option
                                                    key={sport._id}
                                                    value={sport._id}
                                                >
                                                    {sport.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </InputField1>

                                <InputField1
                                    onChange={(e) =>
                                        setBidPlayer(e.target.value)
                                    }
                                >
                                    <Label>Player</Label>
                                    <select>
                                        <option value="" />
                                        {players.map((player) => {
                                            return (
                                                <option
                                                    key={player._id}
                                                    value={player._id}
                                                >
                                                    {player.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </InputField1>

                                <InputField1
                                    onChange={(e) => setBidTeam(e.target.value)}
                                >
                                    <Label>Team</Label>
                                    <select>
                                        <option value="" />
                                        {teams.map((team) => {
                                            return (
                                                <option
                                                    key={team._id}
                                                    value={team._id}
                                                >
                                                    {team.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </InputField1>
                                <InputField1>
                                    <Label>
                                        Amount (negative amount to reduce pool)
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder=""
                                        value={bidAmount}
                                        onChange={(e) =>
                                            setBidAmount(e.target.value)
                                        }
                                    />
                                </InputField1>
                                <Button
                                    onClick={(e) => {
                                        addBid(e);
                                        clearFields();
                                    }}
                                >
                                    Add Team
                                </Button>
                            </Columns>
                        </FormDiv>
                        <Enter>Bids History</Enter>
                        <TabContainer>
                            {bids.map((bid) => {
                                const date = moment(bid.createdAt).format(
                                    'YYYY-MM-DD'
                                );

                                return (
                                    <FanContent key={bid?._id}>
                                        <Box>
                                            <PlayerInfo>
                                                <PlayerNameContainer>
                                                    <BidEmail>
                                                        {bid?.userEmail}
                                                    </BidEmail>
                                                </PlayerNameContainer>
                                            </PlayerInfo>
                                        </Box>
                                        <Box>
                                            <PlayerInfo>
                                                <PlayerNameContainer>
                                                    <PlayerName>
                                                        {bid?.playerName}
                                                    </PlayerName>
                                                </PlayerNameContainer>
                                            </PlayerInfo>
                                        </Box>
                                        <Box>
                                            <PlayerInfo>
                                                <PlayerNameContainer>
                                                    <PlayerName>
                                                        {bid?.teamName}
                                                    </PlayerName>
                                                </PlayerNameContainer>
                                            </PlayerInfo>
                                        </Box>
                                        <Box>
                                            <TabPlayerYear>
                                                <Span>
                                                    ${bid?.amount.toFixed(2)}
                                                </Span>
                                            </TabPlayerYear>
                                        </Box>
                                        <Box>
                                            <TabPlayerYear>
                                                <Span>{date}</Span>
                                            </TabPlayerYear>
                                        </Box>
                                        <Box>
                                            <TabPlayerYear>
                                                <RefundButton
                                                    onClick={(e) => {
                                                        refundUser(e, bid);
                                                    }}
                                                >
                                                    Refund User
                                                </RefundButton>
                                            </TabPlayerYear>
                                        </Box>
                                    </FanContent>
                                );
                            })}
                        </TabContainer>
                    </FanFormOuter>
                </FanSignInForm>
            </SectionFanFeatureMain>
        </>
    );
};

export default AdminManager;
const SectionFanFeatureMain = styled.section`
    background: linear-gradient(
        45deg,
        rgba(61, 37, 63, 1) 0%,
        rgba(19, 32, 41, 1) 50%,
        rgba(32, 81, 87, 1) 100%
    );
    text-align: center;
`;
const FanSignInForm = styled.div`
    display: flex;
    padding: 110px 0;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const Span = styled.span`
    font-size: 15px;

    font-weight: 500;
    color: #60929c;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

const Box = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    border-right: 1px solid rgb(192, 192, 192);
    height: 25%;

    @media (max-width: 768px) {
        display: inline-flex;
        flex-direction: column;

        height: 100%;
        width: 100%;
    }
`;

const PlayerInfo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 768px) {
        margin: 0 0 0 15px;
    }

    flex: 1;
`;

const PlayerNameContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;

    margin: 0 0 0 15px;
`;

const PlayerName = styled.h3`
    font-size: 15px;
    text-align: left;

    @media (min-width: 768px) {
        width: 50%;
    }
`;

const BidEmail = styled.h3`
    font-size: 13px;
    text-align: left;

    @media (min-width: 768px) {
        width: 50%;
    }
`;

const TabPlayerYear = styled.div`
    display: flex;
    justify-content: flex-end;

    @media (max-width: 768px) {
        margin: 15px 0 10px 0;
    }
`;

const TabContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 75%;
`;

const FanContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 15px;
    background-color: white;
    margin-bottom: 3px;
    width: 85%;
    height: 50px;
`;

const FanFormOuter = styled.div`
    background-image: linear-gradient(
        240deg,
        hsl(295deg 26% 20%) 0%,
        hsl(242deg 23% 19%) 20%,
        hsl(212deg 37% 14%) 40%,
        hsl(200deg 40% 14%) 60%,
        hsl(193deg 45% 19%) 80%,
        hsl(187deg 46% 23%) 100%
    );
    border-radius: 25px;
    max-width: 1400px;
    width: 100%;
    margin: 0 20px;
    @media (max-width: 1200px) {
        width: 80%;
    }
`;
const Create = styled.div`
    text-transform: uppercase;
    color: #ffffff;
    font-size: 60px;
    font-weight: 400;

    margin-top: 50px;
    text-align: center;
`;
const Enter = styled.p`
    font-size: 16px;
    color: #adb9d8;

    margin: 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
`;
const FormDiv = styled.div`
    align-items: center;
    margin-bottom: 60px;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1200px) {
        flex-direction: column;
    }
`;
const SplitDiv = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: 720px) {
        display: inline-flex;
        flex-direction: row;
    }
`;

const Label = styled.label`
    font-size: 12px;
    line-height: 12px;
    color: #adb0b1;

    text-align: left;
`;
const Input = styled.input`
    margin-right: 90px;
    outline: none;
    border: 0;
    font-size: 14px;
    line-height: 10px;
    color: #475063;

    text-align: left;
    width: 100%;
    @media (max-width: 1360px) {
        margin-right: 50px;
        transition: 0.5s;
    }
`;
const InputField = styled.div`
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    border: 0;
    height: 45px;

    margin: 10px 10px;
    padding: 10px 20px;
    @media (max-width: 720px) {
    }
`;
const InputField1 = styled.div`
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    border: 0;
    height: 45px;

    width: 97%;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 10px;
    /* margin: 0 auto 27px; */
    padding: 10px 20px;
    @media (max-width: 1360px) {
        max-width: 500px;
        transition: 0.5s;
    }
`;
const Columns = styled.div`
    margin: 0 auto;

    @media (max-width: 720px) {
    }
`;

const FanSignCheck = styled.div`
    align-items: center;
    margin-left: 25px;
    display: flex;
    justify-content: space-between;
`;

const InputCheckBox = styled.input``;

const LabelCheck = styled.label`
    margin-bottom: 5px;
    margin-left: 5px;
    color: white;
    font-size: 14px;
`;

const Button = styled.button`
    background-color: #b7e9ff;
    width: 92%;
    font-size: 16px;
    font-weight: bold;

    height: 55px;
    border-radius: 15px;
    margin-top: 10px;
    margin-bottom: 34px;
    transition: 0.5s ease;
    border: 2px solid transparent;

    &:hover {
        cursor: pointer;
        background-image: linear-gradient(
            240deg,
            hsl(295deg 26% 20%) 0%,
            hsl(242deg 23% 19%) 20%,
            hsl(212deg 37% 14%) 40%,
            hsl(200deg 40% 14%) 60%,
            hsl(193deg 45% 19%) 80%,
            hsl(187deg 46% 23%) 100%
        );
        color: white;
        border: white solid 2px;
    }

    @media (max-width: 1360px) {
    }
    @media (max-width: 1200px) {
        width: 55%;
    }
    @media (max-width: 770px) {
    }
`;

const RefundButton = styled.button`
    background-color: #fa0707;
    width: 100%;
    font-size: 15px;
    font-weight: bold;

    height: 42px;
    border-radius: 15px;
    margin-top: 4px;
    margin-bottom: 4px;
    transition: 0.5s ease;
    border: 2px solid transparent;

    &:hover {
        cursor: pointer;
        background-image: linear-gradient(
            240deg,
            hsl(295deg 26% 20%) 0%,
            hsl(242deg 23% 19%) 20%,
            hsl(212deg 37% 14%) 40%,
            hsl(200deg 40% 14%) 60%,
            hsl(193deg 45% 19%) 80%,
            hsl(122deg 46% 23%) 100%
        );
        color: white;
        border: white solid 2px;
    }
`;
