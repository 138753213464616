import api from './api.js';

export default {
    async login(userData) {
        return api.post(`/auth/login`, {
            email: userData.email,
            password: userData.password,
            isGmail: userData.isGmail || false,
        });
    },
};
