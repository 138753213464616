import { useState, useEffect } from 'react';
import useWindowDimensions from './useWindowDimensions';

export default function useIsMobileDevice() {
    const windowDimensions = useWindowDimensions();
    const [isMobileDevice, setIsMobileDevice] = useState(false);

    useEffect(() => {
        if (windowDimensions.width <= 768) {
            setIsMobileDevice(true);
        }
    }, []);

    return isMobileDevice;
}
