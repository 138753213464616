import React, { useState } from 'react';
import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

import TwitterLogo from '../../assets/images/twitter.svg';
import InstagramLogo from '../../assets/images/instagram.svg';

import HomePageSection from '../../enums/HomePageSection';
import logo from '../../assets/images/logos/white_logo_transparent_background.png';
import ExternalUrl from '../../enums/ExternalUrl';
import { Utils } from '../../utils';

const StyledDrawer = ({ children, redirect, authenticationAction, user }) => {
    const [opened, setOpened] = useState(false);

    const toggleDrawer = () => {
        setOpened(!opened);
    };

    function redirectTo(url, link = null) {
        redirect(url, link);
        toggleDrawer();
    }

    return (
        <>
            <Button onClick={toggleDrawer}>{children}</Button>
            <Drawer open={opened} onClose={toggleDrawer}>
                <Box
                    role="presentation"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',

                        background:
                            'linear-gradient(45deg, rgba(61, 37, 63, 1) 0%, rgba(19, 32, 41, 1) 50%, rgba(32, 81, 87, 1) 100%)',
                        width: 200,
                        height: '100%',
                    }}
                >
                    <>
                        <Links>
                            <Image
                                src={logo}
                                alt="logo"
                                onClick={() => redirectTo('/')}
                            ></Image>

                            <ListItems onClick={() => redirectTo('/')}>
                                <StyledLink>Home</StyledLink>
                            </ListItems>

                            <ListItems
                                onClick={() =>
                                    redirectTo(
                                        '/',
                                        HomePageSection.HOW_IT_WORKS
                                    )
                                }
                            >
                                <StyledLink>How it works</StyledLink>
                            </ListItems>

                            <ListItems
                                onClick={() =>
                                    redirectTo(
                                        '/',
                                        HomePageSection.SELECT_A_SPORT
                                    )
                                }
                            >
                                <StyledLink>Select a Sport</StyledLink>
                            </ListItems>

                            <ListItems
                                onClick={() => redirectTo('/edit-profile')}
                            >
                                <StyledLink>Profile</StyledLink>
                            </ListItems>

                            <ListItems onClick={authenticationAction}>
                                <StyledLink>
                                    {user ? 'Logout' : 'Login'}
                                </StyledLink>
                            </ListItems>
                        </Links>

                        <Socials>
                            <ListItems
                                onClick={() =>
                                    Utils.openExternalUrl(
                                        ExternalUrl.TWITTER_ACCOUNT
                                    )
                                }
                            >
                                <StyledLink>
                                    <img
                                        src={TwitterLogo}
                                        alt={'Twitter'}
                                    ></img>
                                </StyledLink>
                            </ListItems>

                            <ListItems
                                onClick={() =>
                                    Utils.openExternalUrl(
                                        ExternalUrl.INSTAGRAM_ACCOUNT
                                    )
                                }
                            >
                                <StyledLink>
                                    <img
                                        src={InstagramLogo}
                                        alt={'Instagram'}
                                    ></img>
                                </StyledLink>
                            </ListItems>
                        </Socials>
                    </>
                </Box>
            </Drawer>
        </>
    );
};

const ListItems = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 20px;

    &:hover {
        border-bottom: 4px solid white;
        cursor: pointer;
    }
`;

const Image = styled.img`
    object-fit: contain;
    height: 100px;
    width: 100px;

    &:hover {
        cursor: pointer;
    }
`;

const StyledLink = styled(Link)`
    text-transform: uppercase;
    color: white;
    text-decoration: none;
    justify-content: center;
    align-items: center;
`;

const Links = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 90%;
`;

const Socials = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    width: 30%;
`;

export default StyledDrawer;
