import api from './api.js';

export default {
    async get(playerId) {
        return api.get(`/player/get/${playerId}`);
    },

    async add(player) {
        return api.post(`/player/add`, player);
    },

    async getAll(sportId) {
        return api.get(`/player/get-all/${sportId}`);
    },

    async getAllAndBids(sportId) {
        return api.get(`/player/get-all-and-bids/${sportId}`);
    },

    async getAllFeatured() {
        return api.get(`/player/get-all-featured`);
    },
};
