import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Date onClick={onClick} ref={ref}>
        {value ? value : 'Click to Select a Date'}
    </Date>
));

export const MyDatePicker = ({ label, value, setDate }) => {
    return (
        <InputField>
            <Label>{label}</Label>

            <DatePicker
                selected={value}
                onChange={(date) => setDate(date)}
                customInput={<ExampleCustomInput />}
                closeOnScroll
                dateFormat="yyyy-MM-dd"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
            />
        </InputField>
    );
};

const InputField = styled.div`
    display: flex;

    justify-content: flex-start;
    align-items: flex-start;

    background-color: #fff;
    border-radius: 15px;
    flex-wrap: nowrap;
    flex-direction: column;
    border: 0;
    height: 45px;

    width: 100%;

    margin: 10px 10px;
    padding: 10px 20px;
    @media (max-width: 720px) {
    }
`;

const Label = styled.label`
    font-size: 12px;
    line-height: 12px;
    color: #adb0b1;

    text-align: left;
`;

const Date = styled.p`
    font-size: 14px;
    color: gray;
`;
