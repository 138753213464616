import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Utils } from '../utils/';
import { PikdUtils } from 'pikd-utils';

import image from '../assets/images/hero.jpg';

import { userApi } from '../api';
import Toaster from '../plugin/Toaster';
import useIsMobileDevice from '../hooks/useIsMobileDevice';
import ExternalUrl from '../enums/ExternalUrl';
import { MyDatePicker } from '../components/UIElements/DatePicker';

function Signup() {
    const navigate = useNavigate();
    const isMobileDevice = useIsMobileDevice();

    const initialUserForm = {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        city: '',
        postal_code: '',
        address: '',
        password: '',
        confirmPassword: '',
        birth_date: null,
    };

    const [user, setUser] = useState(initialUserForm);
    const [termsAccepted, setTermsAccepted] = useState(false);

    const registerDisabled = () => {
        return (
            !user.first_name ||
            !user.last_name ||
            !user.email ||
            !user.phone_number ||
            !user.city ||
            !user.postal_code ||
            !user.address ||
            !user.password ||
            !user.confirmPassword ||
            !user.birth_date ||
            !termsAccepted
        );
    };

    function setUserForm(payload) {
        setUser({ ...user, ...payload });
    }

    async function signUp(event) {
        event.preventDefault();
        if (registerDisabled()) {
            Toaster.warning(
                'Please make sure that you have filled every section of the form and accepted the terms and conditions.'
            );
            return;
        }

        if (!Utils.isEmailValid(user.email)) {
            Toaster.error('Please enter a valid email');
            return;
        }

        if (!Utils.isPasswordValid(user.password)) {
            Toaster.error(
                'Please make sure your password contains at least 1 uppercase letter, 1 lowercase letter, 1 special character and 1 number'
            );
            return;
        }

        if (user.password !== user.confirmPassword) {
            Toaster.error(
                'Make sure that your password and confirmation match'
            );
            return;
        }

        if (!PikdUtils.isMajor(user.birth_date)) {
            Toaster.error(
                'You need to be major to access the platform functionalities.'
            );
            return;
        }

        try {
            const response = await userApi.signUp(user);

            localStorage.setItem('user', JSON.stringify(response.data));

            navigate('/edit-profile');
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    return (
        <>
            <SectionFanFeatureMain>
                <FanFormOuter>
                    <Create>Create Account</Create>
                    <Enter>Enter your details to Create your Account</Enter>

                    <FormDiv>
                        <Columns>
                            <SplitDiv>
                                <InputField>
                                    <Label>First Name</Label>
                                    <Input
                                        type="text"
                                        placeholder="Enter First Name"
                                        value={user.first_name}
                                        onChange={(e) =>
                                            setUserForm({
                                                first_name: e.target.value,
                                            })
                                        }
                                    ></Input>
                                </InputField>

                                <InputField>
                                    <Label>Last Name</Label>
                                    <Input
                                        type="text"
                                        placeholder="Enter Last Name"
                                        value={user.last_name}
                                        onChange={(e) =>
                                            setUserForm({
                                                last_name: e.target.value,
                                            })
                                        }
                                    ></Input>
                                </InputField>
                            </SplitDiv>

                            <SplitDiv>
                                <InputField>
                                    <Label>Enter Email</Label>
                                    <Input
                                        type="email"
                                        placeholder="Enter Email"
                                        value={user.email}
                                        onChange={(e) =>
                                            setUserForm({
                                                email: e.target.value,
                                            })
                                        }
                                    ></Input>
                                </InputField>

                                <InputField>
                                    <Label>Phone Number</Label>
                                    <Input
                                        type="phone"
                                        placeholder="Enter Phone Number"
                                        value={user.phone_number}
                                        onChange={(e) =>
                                            setUserForm({
                                                phone_number: e.target.value,
                                            })
                                        }
                                    ></Input>
                                </InputField>
                            </SplitDiv>

                            <SplitDiv>
                                <MyDatePicker
                                    label={'Birth date'}
                                    value={user.birth_date}
                                    setDate={(date) =>
                                        setUserForm({ birth_date: date })
                                    }
                                />

                                <InputField>
                                    <Label>Street Address</Label>
                                    <Input
                                        type="address"
                                        placeholder="Enter Street Address"
                                        value={user.address}
                                        onChange={(e) =>
                                            setUserForm({
                                                address: e.target.value,
                                            })
                                        }
                                    ></Input>
                                </InputField>
                            </SplitDiv>

                            <SplitDiv>
                                <InputField>
                                    <Label>City</Label>
                                    <Input
                                        type="text"
                                        placeholder="Enter City"
                                        value={user.city}
                                        onChange={(e) =>
                                            setUserForm({
                                                city: e.target.value,
                                            })
                                        }
                                    ></Input>
                                </InputField>

                                <InputField>
                                    <Label>Postal Code</Label>
                                    <Input
                                        type="text"
                                        placeholder="Enter Postal Code"
                                        value={user.postal_code}
                                        onChange={(e) =>
                                            setUserForm({
                                                postal_code: e.target.value,
                                            })
                                        }
                                    ></Input>
                                </InputField>
                            </SplitDiv>

                            <SplitDiv>
                                <InputField>
                                    <Label>Password</Label>
                                    <Input
                                        type="Password"
                                        placeholder="*********"
                                        value={user.password}
                                        onChange={(e) =>
                                            setUserForm({
                                                password: e.target.value,
                                            })
                                        }
                                    ></Input>
                                </InputField>

                                <InputField>
                                    <Label>Confirm Password</Label>
                                    <Input
                                        type="Password"
                                        placeholder="*********"
                                        value={user.confirmPassword}
                                        onChange={(e) =>
                                            setUserForm({
                                                confirmPassword: e.target.value,
                                            })
                                        }
                                    ></Input>
                                </InputField>
                            </SplitDiv>

                            <FanSignCheck>
                                <InputCheckBox
                                    type="checkbox"
                                    id="remember"
                                    name="remember"
                                    value="remember"
                                    onClick={() =>
                                        setTermsAccepted(!termsAccepted)
                                    }
                                />

                                <LabelCheck>
                                    Accept{' '}
                                    <Link
                                        href={ExternalUrl.TERMS_AND_CONDITIONS}
                                        target={'_blank'}
                                    >
                                        Terms and Conditions
                                    </Link>{' '}
                                    &{' '}
                                    <Link
                                        href={ExternalUrl.PRIVACY_POLICY}
                                        target={'_blank'}
                                    >
                                        Privacy Policy
                                    </Link>
                                </LabelCheck>
                            </FanSignCheck>

                            <Button
                                onClick={(event) => signUp(event)}
                                isDisabled={registerDisabled()}
                            >
                                REGISTER NOW
                            </Button>
                        </Columns>

                        {!isMobileDevice && (
                            <Columns>
                                <ImageDiv>
                                    <Image src={image} alt="signup-img"></Image>
                                </ImageDiv>
                            </Columns>
                        )}
                    </FormDiv>
                </FanFormOuter>
            </SectionFanFeatureMain>
        </>
    );
}

export default Signup;

const SectionFanFeatureMain = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 90vh;

    background: linear-gradient(
        45deg,
        rgba(61, 37, 63, 1) 0%,
        rgba(19, 32, 41, 1) 50%,
        rgba(32, 81, 87, 1) 100%
    );
    text-align: center;
`;

const FanFormOuter = styled.div`
    margin: 60px;
    background-image: linear-gradient(
        240deg,
        hsl(295deg 26% 20%) 0%,
        hsl(242deg 23% 19%) 20%,
        hsl(212deg 37% 14%) 40%,
        hsl(200deg 40% 14%) 60%,
        hsl(193deg 45% 19%) 80%,
        hsl(187deg 46% 23%) 100%
    );
    border-radius: 25px;
    max-width: 1400px;
    width: 100%;

    @media (max-width: 768px) {
        margin: 20px 10px 20px 10px;
    }
`;

const Create = styled.div`
    text-transform: uppercase;
    color: #ffffff;
    font-size: 60px;
    font-weight: 400;

    margin-top: 50px;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

const Enter = styled.p`
    font-size: 16px;
    color: #adb9d8;

    margin: 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 768px) {
        font-size: 12px;
        margin: 10px 0 0 0;
    }
`;

const FormDiv = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const SplitDiv = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
        display: inline-flex;
        flex-direction: column;
        width: 100%;
    }
`;

const Label = styled.label`
    font-size: 12px;
    line-height: 12px;
    color: #adb0b1;

    text-align: left;
`;

const Link = styled.a`
    text-decoration: underline;
    color: white;
`;

const Input = styled.input`
    margin-right: 90px;
    outline: none;
    border: 0;
    font-size: 14px;
    line-height: 10px;
    color: #475063;

    text-align: left;
    width: 100%;
    @media (max-width: 1360px) {
        margin-right: 50px;
        transition: 0.5s;
    }
`;

const InputField = styled.div`
    display: flex;

    justify-content: flex-start;
    align-items: flex-start;

    background-color: #fff;
    border-radius: 15px;
    flex-wrap: nowrap;
    flex-direction: column;
    border: 0;
    height: 45px;

    width: 100%;

    margin: 10px 10px;
    padding: 10px 20px;
    @media (max-width: 720px) {
    }
`;

const DatePickerContainer = styled.div`
    display: flex;

    justify-content: flex-start;
    align-items: flex-start;

    background-color: #fff;
    border-radius: 15px;
    flex-wrap: nowrap;
    flex-direction: column;
    border: 0;
    height: 45px;

    width: 100%;

    margin: 10px 10px;
    padding: 10px 20px;

    &:hover {
        cursor: pointer;
    }
`;

const Columns = styled.div``;

const ImageDiv = styled.div`
    margin-right: 150px;
    margin-left: 100px;
`;

const Image = styled.img`
    transform: scale(1.22);
    vertical-align: middle;
    border-radius: 15px;
    width: 550px;
    height: 340px;
    object-fit: cover;
`;

const FanSignCheck = styled.div`
    align-items: center;
    margin: 10px 0 0 25px;
    display: flex;
    justify-content: flex-start;
`;

const InputCheckBox = styled.input``;

const LabelCheck = styled.div`
    margin-bottom: 5px;
    margin-left: 5px;
    color: white;
    font-size: 14px;
`;

const Button = styled.button`
    background-color: #b7e9ff;
    width: 90%;
    font-size: 16px;
    font-weight: bold;
    font-family: 'avenir_lt_std95_black', serif;
    height: 55px;
    border-radius: 15px;
    margin-top: 24px;
    transition: 0.5s ease;
    border: 2px solid transparent;
    color: #00525f;
    opacity: ${(props) => (props.isDisabled ? '0.7' : '1')};

    &:hover {
        cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
        ${(props) =>
            props.isDisabled
                ? ''
                : 'background-image: linear-gradient(\n            240deg,\n            hsl(295deg 26% 20%) 0%,\n            hsl(242deg 23% 19%) 20%,\n            hsl(212deg 37% 14%) 40%,\n            hsl(200deg 40% 14%) 60%,\n            hsl(193deg 45% 19%) 80%,\n            hsl(187deg 46% 23%) 100%\n        )'};

        color: ${(props) => (props.isDisabled ? '#00525f' : 'white')};
        border: white solid 2px;
    }
`;
