import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import { authenticationApi } from '../api';
import { useValidatedEmail } from '../hooks/InputValidation/useValidatedEmail';
import Toaster from '../plugin/Toaster';
import { Utils } from '../utils';

function Signin() {
    let navigate = useNavigate();

    const [password, setPassword] = useState();
    const [email, setEmail, emailValid] = useValidatedEmail();

    const signUpClick = () => {
        navigate('/sign-up');
    };

    async function login(event) {
        event.preventDefault();

        if (!emailValid) {
            Toaster.error('Please enter a valid email');
            return;
        }

        try {
            const response = await authenticationApi.login({
                email,
                password,
            });

            localStorage.setItem('user', JSON.stringify(response.data));

            navigate('/edit-profile');
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    return (
        <SectionFanFeatureMain>
            <FanForumOuter>
                <SignIn>Sign in</SignIn>
                <Request>Sign in to your account</Request>

                <SignInForm>
                    <InputField>
                        <Label>Email or username</Label>
                        <Input
                            type="email"
                            placeholder="example@gmail.com"
                            onChange={(e) => setEmail(e.target.value)}
                        ></Input>
                    </InputField>
                    <InputField>
                        <Label>Password</Label>
                        <Input
                            type="password"
                            placeholder="*********"
                            onChange={(e) => setPassword(e.target.value)}
                        ></Input>
                    </InputField>

                    <RememberMe>
                        <Div1>
                            <CheckBox
                                type="checkbox"
                                id="remember"
                                value="remember"
                            ></CheckBox>
                            <InputLabel for="remember">Remember me</InputLabel>
                        </Div1>

                        <Div2>
                            <ForgotPassword href="/forgot-password">
                                Forgot Password?
                            </ForgotPassword>
                        </Div2>
                    </RememberMe>

                    <Button onClick={(event) => login(event)}>LOGIN</Button>

                    <DontHaveAnAccount>
                        Don't have an account?
                    </DontHaveAnAccount>

                    <Button onClick={signUpClick}>SIGN UP</Button>
                </SignInForm>
            </FanForumOuter>
        </SectionFanFeatureMain>
    );
}

export default Signin;

const SectionFanFeatureMain = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(
        45deg,
        rgba(61, 37, 63, 1) 0%,
        rgba(19, 32, 41, 1) 50%,
        rgba(32, 81, 87, 1) 100%
    );
`;

const FanForumOuter = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    padding: 50px;
    margin: 100px 10px 100px 10px;

    width: 30%;

    background-image: linear-gradient(
        240deg,
        hsl(295deg 26% 20%) 0%,
        hsl(242deg 23% 19%) 20%,
        hsl(212deg 37% 14%) 40%,
        hsl(200deg 40% 14%) 60%,
        hsl(193deg 45% 19%) 80%,
        hsl(187deg 46% 23%) 100%
    );

    border-radius: 25px;

    @media (max-width: 768px) {
        width: 90%;
        margin: 20px 10px 20px 10px;
    }
`;

const SignIn = styled.h2`
    text-transform: uppercase;
    font-family: 'Lucida Grande', serif;
    letter-spacing: 2px;
    color: #adb9d8;
    font-size: 60px;
    font-weight: 400;
    margin: 0;
    text-align: center;
`;

const Request = styled.p`
    font-size: 16px;
    color: #adb9d8;
    font-family: 'avenir_lt_std55_roman', serif;
    margin: 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
`;

const SignInForm = styled.form`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 63px;
    width: 100%;
`;

const InputField = styled.div`
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    border: 0;
    height: 45px;
    max-width: 481px;
    width: 100%;
    margin: 0 auto 27px;
    padding: 10px 20px;
`;

const Label = styled.label`
    font-size: 12px;
    line-height: 12px;
    color: #adb0b1;
    font-family: 'avenir_lt_std55_roman', serif;
    text-align: left;
`;

const Input = styled.input`
    outline: none;
    border: 0;
    font-size: 15px;
    line-height: 20px;
    color: #475063;
    font-family: 'avenir_lt_std55_roman', serif;
    text-align: left;
`;

const RememberMe = styled.div`
    display: flex;
    justify-content: space-between;

    width: 100%;
`;

const CheckBox = styled.input`
    width: 20px;

    &:hover {
        cursor: pointer;
    }
`;

const InputLabel = styled.label`
    font-size: 12px;
    line-height: 12px;
    color: #adb0b1;
    font-family: 'avenir_lt_std55_roman', serif;
    text-align: left;

    &:hover {
        cursor: pointer;
    }
`;

const Div1 = styled.div`
    margin-left: 30px;
    display: flex;
    align-items: center;
`;

const Div2 = styled.div`
    margin-right: 30px;
`;

const ForgotPassword = styled.a`
    color: #b7e9ff;
    font-size: 12px;
    text-decoration: none;
    font-family: 'avenir_lt_std55_roman', serif;
`;

const Button = styled.button`
    background-color: #b7e9ff;
    width: 90%;
    font-size: 16px;
    font-weight: bold;
    font-family: 'avenir_lt_std95_black', serif;
    height: 55px;
    border-radius: 15px;
    margin-top: 24px;
    transition: 0.5s ease;
    border: 2px solid transparent;
    color: #00525f;

    &:hover {
        cursor: pointer;
        background-image: linear-gradient(
            240deg,
            hsl(295deg 26% 20%) 0%,
            hsl(242deg 23% 19%) 20%,
            hsl(212deg 37% 14%) 40%,
            hsl(200deg 40% 14%) 60%,
            hsl(193deg 45% 19%) 80%,
            hsl(187deg 46% 23%) 100%
        );
        color: white;
        border: white solid 2px;
    }
`;

const DontHaveAnAccount = styled.p`
    color: #adb0b1;
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 10px;
`;

const SignUp = styled.button`
    background-image: linear-gradient(
        240deg,
        hsl(295deg 26% 20%) 0%,
        hsl(242deg 23% 19%) 20%,
        hsl(212deg 37% 14%) 40%,
        hsl(200deg 40% 14%) 60%,
        hsl(193deg 45% 19%) 80%,
        hsl(187deg 46% 23%) 100%
    );

    border: 2px solid white;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    color: #adb0b1;
    font-weight: bold;
    letter-spacing: 1px;

    &:hover {
        cursor: pointer;
        background: #b7e9ff;
        color: #15262f;
        transition: 0.5s;
    }
`;
