import React from 'react';
import styled from 'styled-components';

import logo from '../../assets/images/logos/white_logo_transparent_background.png';
import TwitterLogo from '../../assets/images/twitter.svg';
import InstagramLogo from '../../assets/images/instagram.svg';

import { useValidatedEmail } from '../../hooks/InputValidation/useValidatedEmail';
import Toaster from '../../plugin/Toaster';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Utils } from '../../utils';
import ExternalUrl from '../../enums/ExternalUrl';
import { useNavigate } from 'react-router-dom';

function Footer() {
    const { navigate } = useNavigate();
    const { width: deviceWidth } = useWindowDimensions();

    const [email, setEmail, validated] = useValidatedEmail();

    function subscribeToEmail() {
        if (!validated) {
            Toaster.error('Please enter a valid email');
        }

        // TODO: Email subscription
    }

    return (
        <Container>
            {deviceWidth > 768 && (
                <DescriptionContainer>
                    <LogoImage src={logo} />

                    {/* <DescriptionText>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.
          </DescriptionText> */}

                    <SocialMediaContainer>
                        <SocialMediaImage
                            src={TwitterLogo}
                            alt={'Twitter'}
                            onClick={() => {
                                Utils.openExternalUrl(
                                    ExternalUrl.TWITTER_ACCOUNT
                                );
                            }}
                        ></SocialMediaImage>
                        <SocialMediaImage
                            src={InstagramLogo}
                            alt={'Twitter'}
                            onClick={() => {
                                Utils.openExternalUrl(
                                    ExternalUrl.INSTAGRAM_ACCOUNT
                                );
                            }}
                        ></SocialMediaImage>
                    </SocialMediaContainer>
                </DescriptionContainer>
            )}

            <SectionContainer>
                <Title>ABOUT US</Title>
                {/*<AboutUsLink onClick={() => {}}>Philanthropy</AboutUsLink>*/}
                {/*<AboutUsLink onClick={() => {}}>Fan Sweepstakes</AboutUsLink>*/}
                <AboutUsLink
                    onClick={() => {
                        Utils.openExternalUrl(ExternalUrl.TERMS_AND_CONDITIONS);
                    }}
                >
                    Terms and Conditions
                </AboutUsLink>
                <AboutUsLink
                    onClick={() => {
                        Utils.openExternalUrl(ExternalUrl.PRIVACY_POLICY);
                    }}
                >
                    Privacy Policy
                </AboutUsLink>
            </SectionContainer>

            <SectionContainer>
                <Title>CONTACT US</Title>
                <AboutUsLink href={'mailto:info@pidk.live'} target={'_blank'}>
                    info@pikd.live
                </AboutUsLink>
            </SectionContainer>

            {/* <NewsLetterContainer>
        <Title>NEWSLETTER</Title>

        <InputField>
          <Input type="text" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </InputField>

        <SubscribeButtonContainer onClick={subscribeToEmail}>SUBSCRIBE</SubscribeButtonContainer>
      </NewsLetterContainer> */}
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: row;

    @media (max-width: 1100px) {
    }

    background: linear-gradient(
        45deg,
        rgba(61, 37, 63, 1) 0%,
        rgba(19, 32, 41, 1) 50%,
        rgba(32, 81, 87, 1) 100%
    );

    padding-bottom: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const DescriptionContainer = styled.div`
    display: flex;
    flex: 2;
    flex-direction: column;

    padding: 40px 90px 0 90px;
`;

const DescriptionText = styled.p`
    color: white;
    opacity: 0.7;

    margin: 10px 0 0 10px;
`;

const SectionContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    padding: 40px 0 0 0;

    @media (max-width: 768px) {
        justify-content: center;
        padding-left: 30px;
    }
`;

const AboutUsLink = styled.a`
    color: white;
    opacity: 0.7;

    margin-bottom: 10px;

    text-decoration: none;

    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }
`;

const NewsLetterContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    padding: 40px 0 0 0;

    @media (max-width: 768px) {
        justify-content: center;
        align-items: center;
    }
`;

const LogoImage = styled.img`
    height: 20%;
    width: 15%;
`;

const SocialMediaContainer = styled.div`
    display: flex;
    flex-direction: row;

    padding-left: 15px;

    margin-top: 15px;
`;

const SocialMediaImage = styled.img`
    height: 20px;
    width: 20px;

    margin-right: 20px;

    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
`;

const Title = styled.h3`
    color: white;
    font-size: 20px;
    margin: ${(props) => (props.margin ? props.margin : '0 0 30px 0')};
`;

const InputField = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: center;

    background-color: #f5f5f5;

    height: 50px;
    width: 375px;

    padding: 15px;

    border-radius: 15px;

    @media (max-width: 768px) {
        width: 340px;
    }
`;

const Input = styled.input`
    background-color: #f5f5f5;
    font-size: 20px;
    outline: none;
    border: none;
    width: 100%;

    line-height: 10px;
    color: #475063;

    text-align: left;
`;

const SubscribeButtonContainer = styled.div`
    display: flex;

    justify-content: center;
    align-items: center;

    background-color: #b8e9ff;

    height: 50px;
    width: 375px;

    margin: 10px 0 0 0;

    border-radius: 15px;

    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }

    @media (max-width: 768px) {
        width: 340px;
    }
`;

export default Footer;
