import api from './api.js';

export default {
    async signUp(user) {
        return api.post(`/user/sign-up`, user);
    },

    async updatePassword(id, password, newPassword) {
        return api.patch(`/user/update-password`, {
            id,
            password,
            newPassword,
        });
    },

    async updateUser(id, updatedFields) {
        return api.patch(`/user/update-user`, { id, updatedFields });
    },

    async getUser(id) {
        return api.get(`/user/${id}`);
    },

    async addBalance(id, amount) {
        return api.post(`/user/add-balance`, { id, amount });
    },

    async forgotPassword(id, newPassword) {
        return api.patch(`/user/forgot-password`, { id, newPassword });
    },
};
