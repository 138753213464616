import api from './api.js';

export default {
    async add(sport) {
        return api.post(`/sport/add`, sport);
    },

    async getAll() {
        return api.get(`/sport/get-all`);
    },

    async get(sportId) {
        return api.get(`/sport/get/${sportId}`);
    },
};
