import { openExternalUrl } from './browser';
import { showBackendError } from './errors';
import { isEmailValid, isPasswordValid } from './validations';

export const Utils = {
    // BROWSER
    openExternalUrl,

    // ERRORS
    showBackendError,

    // Validations
    isEmailValid,
    isPasswordValid,
};
