import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';

import logo from '../../assets/images/logos/white_logo_transparent_background.png';
import UserLogo from '../../assets/images/user-icon.svg';
import TwitterLogo from '../../assets/images/twitter.svg';
import InstagramLogo from '../../assets/images/instagram.svg';
import HeroImages from '../../assets/images/hero.jpg';

import HomePageSection from '../../enums/HomePageSection';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import Drawer from './Drawer.js';
import { useScrollInfo } from '../../hooks/useScrollInfo';
import Scrolling from '../../enums/Scrolling';
import { Utils } from '../../utils';
import ExternalUrl from '../../enums/ExternalUrl';

const NavBar = ({ showShadow }) => {
    const navigate = useNavigate();

    const windowWidth = useWindowDimensions().width;

    const user = JSON.parse(localStorage.getItem('user'));

    const [direction, y] = useScrollInfo();

    function redirect(url, section) {
        navigate(url, {
            state: {
                section: section,
            },
        });
    }

    function authenticationAction() {
        if (user) {
            localStorage.removeItem('user');

            navigate('/sign-in');
        } else {
            redirect('/sign-in');
        }
    }

    const hideNavBar = useMemo(() => {
        return direction === Scrolling.DOWN && y > 90;
    }, [y]);

    return (
        <NavContainer out={hideNavBar}>
            <ShadowContainer showShadow={showShadow}>
                {windowWidth < 720 ? (
                    <Drawer
                        redirect={redirect}
                        authenticationAction={authenticationAction}
                        user={user}
                    >
                        <FiMenu size={50} color={'rgb(255, 255, 255, 0.8)'} />
                    </Drawer>
                ) : (
                    <>
                        <LeftSection>
                            <Image
                                src={logo}
                                alt="logo"
                                onClick={() => navigate('/')}
                            ></Image>

                            <ListItems
                                onClick={() =>
                                    redirect('/', HomePageSection.HOW_IT_WORKS)
                                }
                            >
                                <StyledLink>How it works</StyledLink>
                            </ListItems>

                            <ListItems
                                onClick={() =>
                                    redirect(
                                        '/',
                                        HomePageSection.SELECT_A_SPORT
                                    )
                                }
                            >
                                <StyledLink>Select a Sport</StyledLink>
                            </ListItems>
                        </LeftSection>

                        <RightSection>
                            <ListItems
                                onClick={() =>
                                    Utils.openExternalUrl(
                                        ExternalUrl.TWITTER_ACCOUNT
                                    )
                                }
                            >
                                <StyledLink>
                                    <img
                                        src={TwitterLogo}
                                        alt={'Twitter'}
                                    ></img>
                                </StyledLink>
                            </ListItems>

                            <ListItems
                                onClick={() =>
                                    Utils.openExternalUrl(
                                        ExternalUrl.INSTAGRAM_ACCOUNT
                                    )
                                }
                            >
                                <StyledLink>
                                    <img
                                        src={InstagramLogo}
                                        alt={'Instagram'}
                                    ></img>
                                </StyledLink>
                            </ListItems>

                            <ListItems
                                onClick={() => redirect('/edit-profile')}
                            >
                                <StyledLink>
                                    <img
                                        src={UserLogo}
                                        alt={'User profile'}
                                        style={{ marginTop: '5px' }}
                                    ></img>
                                </StyledLink>
                            </ListItems>

                            <ListItems onClick={authenticationAction}>
                                <StyledLink>
                                    {user ? 'Logout' : 'Login'}
                                </StyledLink>
                            </ListItems>
                        </RightSection>
                    </>
                )}
            </ShadowContainer>
        </NavContainer>
    );
};

const NavContainer = styled.div`
    display: flex;
    flex: 1;

    flex-direction: row;
    margin-top: -5px;
    background-image: url(${HeroImages});
    box-shadow: inset 0 0 0 0 red;
    width: 100%;

    top: 0;
    position: sticky;

    opacity: ${(props) => (props.out ? 0 : 1)};

    transition: opacity 0.5s;
`;

const ShadowContainer = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 38%);
    background-color: rgb(
        0,
        0,
        0,
        ${(props) => (props.showShadow ? '0.1' : '0')}
    );
`;

const LeftSection = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    height: 87px;
`;

const RightSection = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
`;

const Image = styled.img`
    object-fit: contain;
    height: 50%;
    margin: 0 50px 0 20px;

    &:hover {
        cursor: pointer;
    }
`;

const ListItems = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 50px;

    height: 100%;

    &:hover {
        border-bottom: 4px solid white;
        cursor: pointer;
    }
`;

const StyledLink = styled(Link)`
    text-transform: uppercase;
    color: white;
    text-decoration: none;
    justify-content: center;
    align-items: center;
`;

export default NavBar;
