import api from './api.js';

export default {
    async getBidsForPlayerAndSport(playerId, sportId, limit) {
        // TODO: remove this if statement when the website is fully setup
        if (playerId === '') {
            playerId = '63422b6f3a22100fa8445e57';
        }

        return api.get(`/bid/get-bids/${playerId}/${sportId}/${limit}`);
    },

    async placeBid(userId, amount, playerId, teamId, sportId, limit) {
        return api.post(`/bid/place-bid`, {
            userId,
            amount,
            playerId,
            teamId,
            sportId,
            limit,
        });
    },

    async refundBid(bid) {
        return api.post(`/bid/refund-bid`, {
            bid,
        });
    },

    async getAllBidsForUser(userId) {
        return api.get(`/bid/get-all-for-user/${userId}`);
    },

    async getAllBids() {
        return api.get(`/bid/get-all`);
    },
};
