import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import HeroImages from '../assets/images/hero.jpg';
import Logo from '../assets/images/logos/white_logo_transparent_background.png';
import SelectASport from '../assets/images/step-1.svg';
import SelectAPlayer from '../assets/images/step-2.svg';
import SelectATeam from '../assets/images/step-3.svg';
import MakeABid from '../assets/images/step-4.svg';
import DividerImg from '../assets/images/seperator.svg';
import Hockey from '../assets/images/hockey.jpg';
import HomePageSection from '../enums/HomePageSection';
import { playerApi, sportApi } from '../api';
import { Utils } from '../utils';
import { PikdUtils } from 'pikd-utils';

const Home = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const topAthletesRef = useRef();
    const howItWorksRef = useRef();
    const selectASportRef = useRef();

    const [sports, setSports] = useState([]);
    const [players, setPlayers] = useState([]);

    useEffect(() => {
        if (location.state?.section === HomePageSection.TOP_ATHLETES) {
            topAthletesRef.current?.scrollIntoView();
        }

        if (location.state?.section === HomePageSection.HOW_IT_WORKS) {
            howItWorksRef.current?.scrollIntoView();
        }

        if (location.state?.section === HomePageSection.SELECT_A_SPORT) {
            selectASportRef.current?.scrollIntoView();
        }
        (async () => {
            await getAllSports();
            await getAllFeaturedPlayers();
        })();
    }, [location]);

    async function getAllSports() {
        try {
            let r = await sportApi.getAll();
            setSports(r?.data);
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    async function getAllFeaturedPlayers() {
        try {
            let r = await playerApi.getAllFeatured();
            setPlayers(r?.data);
        } catch (e) {
            Utils.showBackendError(e);
        }
    }

    function redirectPlayer(url, player) {
        navigate(url, {
            state: {
                player: player,
            },
        });
    }

    function redirectSport(url, section) {
        navigate(url, {
            state: {
                section: section,
            },
        });
    }

    function signUpClick() {
        navigate('/sign-up');
    }

    return (
        <>
            <FanHeroMain>
                <CompanyLogo src={Logo}></CompanyLogo>

                <HeroLogLine>
                    Help Sign Players to Your Favorite Teams
                </HeroLogLine>

                <SignUp onClick={signUpClick}>SIGN UP NOW</SignUp>
            </FanHeroMain>

            {/* <FanFeatureMain ref={topAthletesRef}>
        <Featured>Featured Players</Featured>

        {players.map((player) => (
          <FanContent>
            <Box>
              <PlayersImage src={player.image || image} alt="hockey player"></PlayersImage>
              <PlayerName>{player.name}</PlayerName>
            </Box>

            <Box>
              <Span>UFA {player.ufaYear}</Span>
            </Box>

            <Box>
              <SelectPlayerButton onClick={() => redirectPlayer(`/profile`, player)}>SELECT PLAYER</SelectPlayerButton>
            </Box>
          </FanContent>
        ))}
      </FanFeatureMain> */}

            <FanWork>
                <Container ref={howItWorksRef}>
                    <HowItWorks>How it Works</HowItWorks>

                    <Column>
                        <FanWorkInner>
                            <FanWorkIcon>
                                <Icon src={SelectASport}></Icon>
                            </FanWorkIcon>
                            <Select>01 Select A Sport</Select>
                        </FanWorkInner>

                        <Divider src={DividerImg}></Divider>

                        <FanWorkInner>
                            <FanWorkIcon>
                                <Icon src={SelectAPlayer}></Icon>
                            </FanWorkIcon>
                            <Select>02 Select A Player</Select>
                        </FanWorkInner>

                        <Divider src={DividerImg}></Divider>

                        <FanWorkInner>
                            <FanWorkIcon>
                                <Icon src={SelectATeam}></Icon>
                            </FanWorkIcon>
                            <Select>03 Select Your Team</Select>
                        </FanWorkInner>

                        <Divider src={DividerImg}></Divider>

                        <FanWorkInner>
                            <FanWorkIcon>
                                <Icon src={MakeABid}></Icon>
                            </FanWorkIcon>
                            <Select>04 Make a Bid</Select>
                        </FanWorkInner>
                    </Column>

                    <FanWorkLast>
                        <ElevatorPitch>
                            All fan bids are pooled together on behalf of your
                            favorite team.
                        </ElevatorPitch>
                        <ElevatorPitch>
                            Bids are reimbursed if the player does not sign with
                            your team!
                        </ElevatorPitch>
                    </FanWorkLast>
                </Container>
            </FanWork>

            <FanSportSelect>
                <ContainerFluid>
                    <FanSportHeading>
                        <HowItWorks>Select a Sport</HowItWorks>
                    </FanSportHeading>

                    <FanSportMain ref={selectASportRef}>
                        {sports.map((sport) => (
                            <ImageContainer
                                onClick={() =>
                                    redirectSport(`/leagues`, sport._id)
                                }
                            >
                                <Sport
                                    src={
                                        PikdUtils.getImageFromDrive(
                                            sport.image
                                        ) || Hockey
                                    }
                                    alt="sport image"
                                />
                                <SportDiv>{sport.name}</SportDiv>
                            </ImageContainer>
                        ))}
                    </FanSportMain>
                </ContainerFluid>
            </FanSportSelect>
        </>
    );
};

const FanHeroMain = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    background-image: url(${HeroImages});
    min-height: 727px;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 38%);
    background-size: cover;
    margin-top: -85px;
`;

const CompanyLogo = styled.img`
    width: 350px;
    margin-bottom: 50px;

    @media (max-width: 290px) {
        width: 200px;
    }
`;
const HeroLogLine = styled.h3`
    font-weight: lighter;
    font-size: 30px;
    color: white;

    text-align: center;

    margin-bottom: 40px;

    @media (max-width: 290px) {
        font-size: 20px;
    }
`;
const FanFeatureMain = styled.div`
    background: linear-gradient(
        45deg,
        rgba(61, 37, 63, 1) 0%,
        rgba(19, 32, 41, 1) 50%,
        rgba(32, 81, 87, 1) 100%
    );
    text-align: center;
    height: 100%;
    padding-bottom: 100px;
`;

const Featured = styled.h3`
    padding-top: 100px;
    text-transform: uppercase;
    font-size: 35px;
    color: white;

    margin: 0 0 40px 0;
`;
const FanContent = styled.div`
    max-width: 25%;
    flex-wrap: wrap-reverse;
    margin: 20px;

    border: 2px solid white;
    background-color: white;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    align-content: center;
    padding: 30px;
    @media (max-width: 800px) {
        max-width: 95%;
    }
    @media (max-width: 450px) {
        max-width: 90%;
    }
`;

const Box = styled.div`
    align-items: center;
    display: inline-flex;
    flex-direction: column;
`;

const PlayersImage = styled.img`
    border-radius: 25px;
    width: 110%;
    height: 300px;
`;

const PlayerName = styled.h3`
    margin: 20px 0 40px 0;
    font-size: 25px;
`;

const Span = styled.span`
    font-family: 'avenir_lt_std55_roman', serif;

    color: #60929c;
`;

const SelectPlayerButton = styled.button`
    font-weight: bold;
    margin-top: 20px;
    background-color: #b7e9ff;
    border-radius: 20px;
    font-size: 15px;

    width: 300px;
    height: 40px;
    text-decoration: none;
    color: #00525f;

    border: none;

    &:hover {
        cursor: pointer;
        background-color: #008b8b;
        color: white;
        transition: 0.5s;
    }
`;

const FanWork = styled.div`
    background: linear-gradient(
        45deg,
        rgba(61, 37, 63, 1) 0%,
        rgba(19, 32, 41, 1) 50%,
        rgba(32, 81, 87, 1) 100%
    );
    text-align: center;

    padding: 0 0 30px 0;

    @media (max-width: 1177px) {
        height: 2200px;
    }
`;

const Container = styled.div`
    padding-top: 100px;
`;

const HowItWorks = styled.h3`
    font-size: 35px;
    text-align: center;
    color: #ffffff;

    text-transform: uppercase;

    margin: 0 0 60px 0;
`;

const FanWorkInner = styled.div`
    @media (max-width: 1177px) {
        margin-bottom: 50px;
    }
`;

const Divider = styled.img`
    @media (max-width: 1268px) {
        display: none;
    }
`;

const FanWorkIcon = styled.div`
    width: 250px;
    height: 250px;
    border: 3px solid #00525f;
    border-radius: 50%;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00525f;
    outline: 3px solid #00525f;
    outline-offset: 9px;
    margin: auto;

    @media (max-width: 290px) {
        width: 200px;
        height: 200px;
    }
`;

const Icon = styled.img`
    width: 100px;
    @media (max-width: 290px) {
        width: 70px;
    }
`;

const Select = styled.p`
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    margin-top: 30px;

    text-transform: uppercase;
    @media (max-width: 290px) {
        font-size: 15px;
    }
`;

const Column = styled.div`
    display: flex;
    justify-content: space-around;
    @media (max-width: 1177px) {
        display: inline-flex;
        flex-direction: column;
    }
`;

const FanWorkLast = styled.div`
    margin-top: 150px;
    margin-bottom: 10px;
`;

const ElevatorPitch = styled.p`
    font-size: 23px;

    @media (max-width: 290px) {
        font-size: 17px;
        padding-right: 15px;
        padding-left: 15px;
    }

    color: #ffffff;
    text-align: center;
`;

const FanSportSelect = styled.div`
    padding-bottom: 50px;
    background: linear-gradient(
        45deg,
        rgba(61, 37, 63, 1) 0%,
        rgba(19, 32, 41, 1) 50%,
        rgba(32, 81, 87, 1) 100%
    );
    text-align: center;
    height: 100%;
`;

const FanSportHeading = styled.div`
    margin-top: 100px;
    margin-bottom: 50px;
`;

const ContainerFluid = styled.div`
    padding-top: 10px;
`;

const FanSportMain = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    @media (max-width: 1100px) {
        display: inline-flex;
        flex-direction: column;
        transition: 500ms;
    }
`;

const Sport = styled.img`
    max-width: 100%;
    border-radius: 32px;
    width: 450px;
    height: 250px;
    border: 8px solid white;

    &:hover {
        font-weight: bold;
        border: 8px solid #b7e9ff;
    }

    @media (max-width: 450px) {
        width: 90%;
    }
`;

const ImageContainer = styled.div`
    position: relative;
    text-align: center;
    color: white;

    margin-bottom: 10px;

    &:hover {
        cursor: pointer;
        font-weight: bold;
        color: #b7e9ff;
    }
`;

const SportDiv = styled.div`
    font-size: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:hover {
        font-weight: bold;
        cursor: pointer;
        transition: 10ms;
    }
`;

const SignUp = styled.button`
    border: 2px solid white;
    border-radius: 10px;

    background-color: #b7e9ff;
    color: #00525f;

    padding: 10px;
    margin-top: 50px;

    font-weight: bold;
    font-size: 20px;
    letter-spacing: 1px;

    width: 200px;
    height: 60px;

    transition: color ease-in-out 0.3s;

    &:hover {
        cursor: pointer;
        background: #b7e9ff;
        color: #adb0b1;
    }
`;

export default Home;
